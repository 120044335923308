import auth from './auth';
import users from './users';
import signalProviders from './signal-providers';
import membershipPlans from './membership-plans';
import recharges from './recharges';
import fees from './fees';
import tradeHistory from './trade-history';
import analystSignupRequests from './analyst-signup-requests';
import blog from './blog';
import imageUpload from './image-upload';
import rechargePlans from './recharge-plans';
import candeFiMembershipPlans from './candefi-membership-plans';
import impersonate from './impersonate';

const api = {
    auth,
    users,
    signalProviders,
    membershipPlans,
    recharges,
    fees,
    tradeHistory,
    analystSignupRequests,
    blog,
    imageUpload,
    rechargePlans,
    candeFiMembershipPlans,
    impersonate,
}

export default api;