import { ProCard, ProForm, ProFormCheckbox, ProFormDigit, ProFormMoney, ProFormSelect, ProFormText } from "@ant-design/pro-components"
import { message } from "antd"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { DisplayBillingIntervalUnit } from "../../api/enum-display"
import { CreateMembershipPlanRequest } from "../../api/membership-plans"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage"
import { ProFormSignalProviderSelect } from "../../shared/components/inputs/ProSignalProviderSelect"

interface CreateMembershipPlanForm extends CreateMembershipPlanRequest {
    signal_provider: { label: string, value: number }
}

export const CreateMembershipPlan = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();

    return <AntPageContainer>
        <ProCard>
            <ProForm<CreateMembershipPlanForm>
                grid
                onFinish={async (data) => {

                    // default value is undefined
                    // but we want false
                    data.options = data.options || false;
                    data.stocks = data.stocks || false;

                    const { signal_provider, ...restData } = data;
                    const payload : CreateMembershipPlanRequest = restData;
                    payload.signal_provider_id = signal_provider.value;

                    try {
                        await api.membershipPlans.create(payload);
                        message.success("Success")
                        navigate("/admin/membership-plans");
                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <ProFormText
                    required
                    label="Name"
                    name="name"
                    rules={[
                        { required: true, message: 'name is required' },
                    ]}
                />
                <ProFormSignalProviderSelect
                    required
                    label="Signal provider"
                    name="signal_provider"
                    rules={[
                        { required: true, message: 'signal provider is required' },
                    ]}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="billing_interval"
                    label="Billing Interval"
                    min={1}
                    rules={[
                        { required: true, message: 'billing interval is required' },
                    ]}
                />
                <ProFormSelect
                    required
                    colProps={{ span: 12 }}
                    name="billing_interval_unit"
                    label="Billing Interval Unit"
                    valueEnum={DisplayBillingIntervalUnit}
                    rules={[
                        { required: true, message: 'billing interval unit is required' },
                    ]}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="billing_cycles"
                    label="Billing Cycles"
                    placeholder="0 for infinite"
                    min={0}
                    rules={[
                        { required: true, message: 'billing cycles is required' },
                    ]}
                />
                <ProFormMoney
                    required
                    colProps={{ span: 12 }}
                    name="amount"
                    label="Amount"
                    min={1}
                    rules={[
                        { required: true, message: 'amount is required' },
                    ]}
                />
                <ProFormCheckbox
                    colProps={{ span: 12 }}
                    name="stocks"
                    label="Stocks"
                    fieldProps={{
                        defaultChecked: false,
                    }}
                />
                <ProFormCheckbox
                    colProps={{ span: 12 }}
                    name="options"
                    label="Options"
                    fieldProps={{
                        defaultChecked: false,
                    }}
                />
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}