import { ProCard, ProForm, ProFormMoney } from "@ant-design/pro-components";
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { AntPageContainer } from "../../shared/components/AntPageContainer";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { AdminChangeMembershipPlanPriceRequest } from "../../api/candefi-membership-plans";

export const EditCandeFiMembershipPlanPrice = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();
    const { id } = useParams();

    return <AntPageContainer>
        <ProCard
            bordered
        >
            <ProForm<AdminChangeMembershipPlanPriceRequest>
                grid
                colProps={{ span: 12 }}
                params={{ id }}
                request={async (params : Record<string, string>) => {
                    try {
                        const plan = await api.candeFiMembershipPlans.findById(params.id);
                        return { price: plan?.price };
                    } catch (e) {
                        console.log(e);
                        navigate('/admin/candefi-membership-plans');
                    }
                }}
                onFinish={async (data) => {

                    try {

                        await api.candeFiMembershipPlans.editPrice(id, data);
                        message.success("Success")
                        navigate("/admin/candefi-membership-plans");

                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <ProFormMoney
                    required
                    colProps={{ span: 24 }}
                    name="price"
                    label="Price"
                    min={1}
                    rules={[
                        { required: true, message: 'price is required' },
                    ]}
                />
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}