import { App as AntApp, ConfigProvider, theme } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import 'antd/dist/reset.css';
import { AuthContextProvider } from './auth/context/AuthContext';
import enUs from 'antd/es/locale/en_US';

function App() {
  return (
    <AuthContextProvider>
      <ConfigProvider 
        locale={enUs}
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: '#7260ff',
            colorLink: '#7260ff',
            colorLinkActive: '#5448d9',
            colorLinkHover: '#9b8aff',
          }
        }}
      >
          <AntApp>
            <RouterProvider router={router} />
          </AntApp>
      </ConfigProvider>
    </AuthContextProvider>
  );
}

export default App;
