import { Table } from "antd"
import { BrokerAccountBasic } from "../../api/entities"
import { DisplayBrokerType } from "../../api/enum-display"

export const UserBrokerAccounts = ({
    brokerAccounts
} : {
    brokerAccounts: BrokerAccountBasic[],
}) => {
    return <Table<BrokerAccountBasic>
        pagination={false}
        dataSource={brokerAccounts}
        columns={[
            { key: 'id', dataIndex: 'id', title: 'ID' },
            {
                key: 'type',
                title: 'Type',
                render: (_, entity) => DisplayBrokerType[entity.type]
            },
            { key: 'username', dataIndex: 'username', title: 'Username' },
        ]}
    />
}