import type { AxiosResponse } from 'axios';
import { LOGIN_PATH, REFRESH_PATH } from '../constants';
import { getAuthInfo, setAuthInfo } from '../utils/token-storage';

export default function saveToken(value: AxiosResponse<any, any>) {

  let path = (value.config.url || '').replace(/\/$/, '');

  if (path === LOGIN_PATH && value.status === 200) {
    setAuthInfo(value.data)
  }

  if (path === REFRESH_PATH && value.status === 200) {
    let authInfo = getAuthInfo()
    if (authInfo === null) {
      return value;
    }
    authInfo.access_token = value.data.access_token;
    authInfo.access_token_expires = value.data.access_token_expires;
    setAuthInfo(authInfo);
  }

  return value;
}