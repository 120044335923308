import { ProFormText, ProFormMoney, ProFormDigit } from "@ant-design/pro-components"


export const RechargePlansFormFields = () => {
    return <>
        <ProFormText
            label="Title"
            name="title"
            rules={[
                { required: true, message: 'title is required' }
            ]}
        />
        <ProFormMoney
            label="Amount"
            name="amount"
            min={1}
            rules={[
                { required: true, message: 'amount is required' }
            ]}
        />
        <ProFormDigit
            label="Coins"
            name="coins"
            min={1}
            rules={[
                { required: true, message: 'coins is required' }
            ]}
            fieldProps={{
                precision: 0
            }}
        />
    </>
}