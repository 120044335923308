import { 
    DashboardOutlined, 
    LogoutOutlined, 
    UserOutlined,
    SignalFilled,
    MediumOutlined,
    DollarOutlined,
    SettingOutlined,
    HistoryOutlined,
    PullRequestOutlined,
    BoldOutlined,
} from '@ant-design/icons';
import { DefaultFooter, ProLayout } from '@ant-design/pro-components';
import { Avatar, Dropdown, Menu } from 'antd';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import { clearAuthInfo } from '../../api/utils/token-storage';
import { useAuthContext } from '../../auth/context/AuthContext';
import whiteLogoIcon from '../images/white-logo-icon.png';
import whiteLogoText from '../images/white-logo-text.png';

export const AdminLayout = () => {
    const { pathname } = useLocation();
    
    const navigate = useNavigate();
    
    const authContext = useAuthContext();

    const logout = async () => { 
        await api.auth.logout();
        clearAuthInfo()
        await authContext.refresh();
    }

    const menuRoutes = {
        path: '',
        routes: [
            {
                path: '/admin/dashboard',
                key: 'dashboard',
                name: 'Dashboard',
                icon: <DashboardOutlined />,
            },
            {
                name: 'Users', 
                path: '/admin/users', 
                icon: <UserOutlined />,
                routes: [
                    { path: 'create', name: 'Create User', hideInMenu: true }, 
                    { path: ':id/view', name: 'User Details', hideInMenu: true }, 
                    { path: ':id/edit', name: 'Edit User', hideInMenu: true }, 
                ]
            },
            {
                name: 'Blogs', 
                path: '/admin/blogs', 
                icon: <BoldOutlined />,
                routes: [
                    { path: 'create', name: 'Create Blog', hideInMenu: true }, 
                    { path: ':id/view', name: 'User Blog', hideInMenu: true }, 
                    { path: ':id/edit', name: 'Edit Blog', hideInMenu: true }, 
                ]
            },
            {
                name: 'Analyst Signup Request', 
                path: '/admin/analyst-signup-requests', 
                icon: <PullRequestOutlined />,
            },
            {
                name: 'Trade History', 
                path: '/admin/trade-history', 
                icon: <HistoryOutlined />,
            },
            {
                name: 'Signal Providers', 
                path: '/admin/signal-providers', 
                icon: <SignalFilled />,
                routes: [
                    { path: 'create', name: 'Create Signal Provider', hideInMenu: true }, 
                    { path: ':id/view', name: 'Signal Provider Details', hideInMenu: true }, 
                    { path: ':id/edit', name: 'Edit Signal Provider', hideInMenu: true }, 
                ]
            },
            {
                name: 'Membership Plans', 
                path: '/admin/membership-plans', 
                icon: <MediumOutlined />,
                routes: [
                    { path: 'create', name: 'Create Membership Plan', hideInMenu: true }, 
                ]
            },
            {
                name: 'CandeFi Membership Plans', 
                path: '/admin/candefi-membership-plans', 
                icon: <MediumOutlined />,
                routes: [
                    { path: 'create', name: 'Create CandeFi Membership Plan', hideInMenu: true }, 
                ]
            },
            {
                name: 'Recharges', 
                path: '/admin/recharges', 
                icon: <DollarOutlined />,
            },
            {
                name: 'Recharge Plans', 
                path: '/admin/recharge-plans', 
                icon: <DollarOutlined />,
                routes: [
                    { path: 'create', name: 'Create Recharge Plan', hideInMenu: true }, 
                    { path: ':id/view', name: 'User Recharge Plan', hideInMenu: true }, 
                    { path: ':id/edit', name: 'Edit Recharge Plan', hideInMenu: true }, 
                ]
            },
            {
                name: 'Fees', 
                path: '/admin/fees', 
                icon: <SettingOutlined />,
            },
        ]
    }

    const renderItem = (item: any, defaultDom: any) => (item && item.path)
        ? <Link to={item.path}> {defaultDom} </Link>
        : <div className='temp'>{defaultDom}</div>

    return <ProLayout

        // General 
        title={<img src={whiteLogoText} height={15}/>}
        logo={<img src={whiteLogoIcon} />}
        layout="side"

        // Routes and nav config
        location={{ pathname }}
        route={menuRoutes}
        menuItemRender={renderItem}
        subMenuItemRender={renderItem}

        // Sider aka sidebar
        fixSiderbar={true}
        siderWidth={300}

        // Header
        // headerRender={false}
        rightContentRender={() => (
            <div style={{ marginBottom: 12 }}>
                <Dropdown
                    trigger={['click']}
                    menu={{
                        onClick: (e) => {
                            if (e.key == 'logout') {
                                logout();
                            }
                            if (e.key == 'change-password') {
                                navigate('/admin/change-password');
                            }
                        },
                        items: [
                            // {
                            //     key: 'change-password',
                            //     label: "Change Password",
                            //     icon: <EditOutlined />,
                            // },
                            {
                                key: 'logout',
                                label: 'Logout',
                                icon: <LogoutOutlined />,
                            }
                        ]
                    }}>
                    <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                </Dropdown>
            </div>
        )}
        // headerContentRender={(props: BasicLayoutProps) => (
        //   <div></div>
        // )}

        // Footer
        footerRender={() => (
            <DefaultFooter copyright="All rights reserved" />
        )}
    >
        <Outlet />
    </ProLayout >
}