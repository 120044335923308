import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserRole } from "../../api/enums";
import { useAuthContext } from "../context/AuthContext";

export const Protected = ({ 
  children 
} : {
  children: ReactNode,
}) => {

  const location = useLocation();

  const {
    loading,
    user,
  } = useAuthContext();

  // when user info is being fetched
  // render nothing
  if (loading) {
    return null;
  }

  // when the user is not logged in
  // redirect the user to login page
  // and save current page in state
  if (user === null || user.role !== UserRole.Admin) {
    return <Navigate 
      to='/auth/login'
      state={{ rom: location }}
      replace
    />
  }

  // show component
  return <>
    { children }
  </>
}