import { Descriptions } from "antd"
import { SignalProviderBasic } from "../../api/entities"
import { DisplaySignalProviderType } from "../../api/enum-display"
import { formatDate } from "../../utils/common"

export const BasicSignalProviderInfo = ({
    signalProvider
} : {
    signalProvider : SignalProviderBasic
}) => {
    return <Descriptions title="Signal Provider">
        <Descriptions.Item label="Name">
            { signalProvider.name }
        </Descriptions.Item>
        <Descriptions.Item label="Username">
            @{ signalProvider.username }
        </Descriptions.Item>
        <Descriptions.Item label="Type">
            { DisplaySignalProviderType[signalProvider.type] }
        </Descriptions.Item>
        <Descriptions.Item label="Bio">
            { signalProvider.bio }
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
            { formatDate(signalProvider.created_at) }
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
            { formatDate(signalProvider.updated_at) }
        </Descriptions.Item>
    </Descriptions>
}