import axios from "./axios";
import { AccountBalanceRechargeWithUser } from "./entities";
import { AccountBalanceRechargeStatus } from "./enums";
import { ListRequest, PaginatedResponse } from "./types";

export class ListRechargesRequest extends ListRequest {
    user_id?: number
    user_name?: string
    user_email?: string
    status?: AccountBalanceRechargeStatus
    min_date?: string
    max_date?: string
}

const rechargesApi = {
    async index(request : ListRechargesRequest) {
        const { data } = await axios.get<PaginatedResponse<AccountBalanceRechargeWithUser>>(
            '/account-balance-recharges',
            { params: request }
        );
        return data;
    }
}

export default rechargesApi;