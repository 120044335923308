import moment from "moment";
import { YesNo } from "../api/enums";

export const MONEY_SYMBOL = '$';

export const camelCaseToSpaceCase = (s : string) => {
  return s.replace(/([a-z])([A-Z])/g, `$1 $2`);
}

export const dashCaseToSpaceCase = (s : string) => {
  return s.replace(/(_|-)/g, ' ');
}

export const formatDate = (d: string, format : string ='MMM-DD-YYYY') => {
  return moment(d).format(format);
}

export const formatMoney = (amount: string | number, places : number = 2) => {

  const amountNumber = typeof('amount') === 'number'
    ? amount
    : parseFloat(amount as string);

  return `${MONEY_SYMBOL}${(amountNumber as number).toFixed(places)}`
}

export const formatNumber = (number: number) => {
  return number.toFixed(2);
}

export const yesNoToBoolean = (yesNo: any) => {
  if (Object.values(YesNo).includes(yesNo)) {
    return {
        [YesNo.Any]: undefined,
        [YesNo.No]: false,
        [YesNo.Yes]: true,
    }[yesNo as YesNo];
  }
  return undefined;
}


export function removeLeadingSlash (str: string) {
  return str.replace(/^\//, '');
}

export function removeTrailingSlash (str: string) {
  return str.replace(/\/$/, '');
}

export function removeSlash(str: string) {
  return removeTrailingSlash(removeLeadingSlash(str))
}

export const DEFAULT_DATE_FORMAT = 'MMM-DD-YYYY'

export const usernameRegex = /^[a-z_0-9]+$/
export const usernameRegexExplanation = 'Only lowercase letters, numbers and underscore are allowed'