import { ProCard, ProForm } from "@ant-design/pro-components"
import { message } from "antd"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { CreateSignalProviderRequest } from "../../api/signal-providers"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage"
import { SignalProviderFormFields } from "../components/SignalProviderFormFields"

interface CreateSignalProviderProviderForm extends CreateSignalProviderRequest {
    owner: { label: string, value: number }
}

export const CreateSignalProvider = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<any>(null);

    return <AntPageContainer>
        <ProCard bordered>
            <ProForm<CreateSignalProviderProviderForm>
                onFinish={async (data) => {
        
                    const { owner, ...restData } = data;
                    const request : CreateSignalProviderRequest = restData;
                    request.owner_id = owner.value;
        
                    try {

                        await api.signalProviders.create(request);
                        message.success("Success")
                        navigate("/admin/signal-providers");

                    } catch (e) {
                        setError(e);
                    }
                }}
                grid
            >
                <SignalProviderFormFields />
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}

