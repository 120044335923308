import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { ProTable } from "@ant-design/pro-components"
import { Button, Popconfirm } from "antd"
import { Link } from "react-router-dom"
import api from "../../api"
import { RechargePlanBasic } from "../../api/entities"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { getErrorMessage } from "../../shared/components/ErrorMessage"
import { message } from "antd";
import { RechargePlanListRequest } from "../../api/recharge-plans"
import { formatMoney } from "../../utils/common"

export const ListRechargePlans = () => {
    const [messageApi, contextHolder] = message.useMessage();
    return <AntPageContainer>
        { contextHolder }
        <ProTable<RechargePlanBasic>
            headerTitle="Recharge Plans"
            cardBordered
            search={false}
            options={{
                density: false,
                setting: false,
            }}
            form={{
                syncToUrl: true,
            }}
            toolBarRender={()=> [
                <Link to='create'>
                  <Button key="create" icon={<PlusOutlined/>} type="primary">
                    Create
                  </Button>
                </Link>,
              ]}
            columns={[
                {
                    key: 'id',
                    dataIndex: 'id',
                    title: 'ID',
                    hideInSearch: true,
                },
                {
                    key: 'title',
                    title: 'Title',
                    dataIndex: 'title',
                    hideInSearch: true,
                },
                {
                    key: 'amount',
                    title: 'Amount',
                    render: (_, row) => formatMoney(row.amount || ''),
                    hideInSearch: true,
                },
                {
                    key: 'coins',
                    title: 'Coins',
                    dataIndex: 'coins',
                    hideInSearch: true,
                },
                {
                    title: 'Actions',
                    key: 'actions',
                    valueType: 'option',
                    render: (_, entity, index, action) => [
                        <Link to={`${entity.id}/edit`}>
                            <EditOutlined/>
                        </Link>,
                        <Popconfirm
                            title='Delete'
                            okText='Yes'
                            cancelText='No'
                            onConfirm={async () => {
                                try {
                                    await api.rechargePlans.deleteById(entity.id)
                                    action?.reload();
                                } catch (e) {
                                    const errorMessage = getErrorMessage(e);
                                    messageApi.success(errorMessage);
                                }
                            }}
                        >
                            <Button danger type="text">
                                <DeleteOutlined  />
                            </Button>
                        </Popconfirm>,
                    ]
                }
            ]}
            request={async (params, sort) => {
                const request = new RechargePlanListRequest();
                request.setPageParams(params);
                return api.rechargePlans.index(request);
            }}
        />
    </AntPageContainer>
}