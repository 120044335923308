import { Button, Form, Input } from "antd";
import { useState } from "react";
import api from "../../api";
import { PasswordResetRequest } from "../../api/auth";
import { ErrorMessage } from "../../shared/components/ErrorMessage";

export const ChangePasswordForm = ({
  token, onSuccess,
}: {
  token: string;
  onSuccess: () => void | Promise<void>;
}) => {

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  return <Form<PasswordResetRequest>
      layout='vertical'
      onFinish={async (data) => {
        try {
          data.token = token;
          setSubmitting(true);
          await api.auth.resetPassword(data);
          onSuccess();
        } catch (e) {
          setError(e);
        } finally {
          setSubmitting(false);
        }
      }}
    >
    <ErrorMessage error={error} />
    <Form.Item
      label="Password"
      name="password"
      rules={[
        { required: true, message: 'password is required' },
        { min: 8, message: 'password must be atleast 8 characters' },
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Form.Item
      dependencies={['password']}
      label="Confirm Password"
      name="password_confirmation"
      rules={[
        { required: true, message: 'confirm your password' },
        ({ getFieldValue }) => ({
          validator: (_, value) => {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('passwords dont match'))
          }
        })
      ]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item>
      <Button
        style={{ width: '100%' }}
        loading={submitting}
        disabled={submitting}
        type="primary"
        size='large'
        htmlType="submit"
      >
        Change Password
      </Button>
    </Form.Item>
  </Form>;
};
