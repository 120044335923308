import { EyeOutlined } from "@ant-design/icons"
import { ProTable } from "@ant-design/pro-components"
import { Button, Modal } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"
import api from "../../api"
import { TradeHistoryListItem } from "../../api/entities"
import { DisplayTradeHistoryAction } from "../../api/enum-display"
import { ListTradeHistoryRequest } from "../../api/trade-history"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { formatDate } from "../../utils/common"

export const ListTradeHistory = () => {
    const [showInfo, setShowInfo] = useState(false);
    const [selectedRow, setSelectedRow] = useState<TradeHistoryListItem|null>(null);

    return <AntPageContainer>
        <Modal title="Trade Detail" 
            open={showInfo}
            footer={null}
            onCancel={() => {
                setShowInfo(false);
                setSelectedRow(null);
            }}>
                {
                    selectedRow?.payload && <pre>
                        { JSON.stringify(JSON.parse(selectedRow.payload), null, 4) }
                    </pre>
                }
        </Modal>
        <ProTable<TradeHistoryListItem>
            headerTitle="Trade History"
            cardBordered
            search={{  
                labelWidth: 'auto',
                searchGutter: 8,
            }}
            options={{
                density: false,
                setting: false,
            }}
            form={{
                syncToUrl: true,
            }}
            request={async (params, sort, filter) => {
                const request = new ListTradeHistoryRequest();
                request.setPageParams(params);
                request.action = params.action;
                request.user_id = params.user_id;
                request.user_name = params.user_name;
                request.user_email = params.user_email;
                if (params.date && Array.isArray(params.date) && params.date.length == 2) {
                    request.min_date = params.date[0] || undefined;
                    request.max_date = params.date[1] || undefined;
                }
                return api.tradeHistory.index(request);
            }}
            columns={[
                { key: 'id', dataIndex: 'id', title: 'ID', hideInSearch: true },
                {
                    key: 'action',
                    dataIndex: 'action',
                    valueType: 'select',
                    valueEnum: DisplayTradeHistoryAction,
                    title: 'Action',
                },
                {
                    key: 'user',
                    title: 'User',
                    render: (_, row) => {
                        const user = row.user;
                        return <Link to={`/admin/users/${user.id}/view`}>
                            {user.name} ({user.email})
                        </Link>;
                    },
                    hideInSearch: true,
                },
                {
                    key: 'createdAt',
                    title: 'Created At',
                    dataIndex: 'created_at',
                    render: (_, row) => formatDate(row.created_at),
                    hideInSearch: true,
                },
                {
                    key: 'updatedAt',
                    title: 'Updated At',
                    dataIndex: 'updated_at',
                    render: (_, row) => formatDate(row.updated_at),
                    hideInSearch: true,
                },
                {
                    key: 'user_id',
                    title: 'User Id',
                    valueType: 'digit',
                    hideInTable: true,
                },
                {
                    key: 'user_name',
                    title: 'User Name',
                    hideInTable: true,
                },
                {
                    key: 'user_email',
                    title: 'User Email',
                    hideInTable: true,
                },
                {
                    key: 'date',
                    title: 'Date',
                    valueType: 'dateTimeRange',
                    hideInTable: true,
                    fieldProps: {
                        allowEmpty: [true, true],
                    }
                },
                {
                    title: 'Actions',
                    key: 'actions',
                    valueType: 'option',
                    render: (_, entity, index, action) => [
                        <Button 
                            type="default" 
                            onClick={() => {
                                setSelectedRow(entity);
                                setShowInfo(true);
                            }}
                        >
                            <EyeOutlined />
                            View Detail
                        </Button>
                    ]
                }
            ]}

        />
    </AntPageContainer>
}