import { removeSlash } from "./common";

const ASSET_URL = process.env.REACT_APP_ASSET_URL || '';

export interface ImageUrlOptions {}

export function getImageUrl(path: string, options?: ImageUrlOptions) {
  const base = removeSlash(ASSET_URL);
  path = removeSlash(path);
  return `${base}/${path}`;
}