import { RechargePlanBasic } from "./entities"
import { GeneircIDResponse, GenericStatusResponse, ID, ListRequest, PaginatedResponse } from "./types"
import axios from './axios';

export class RechargePlanListRequest extends ListRequest {}

export interface CreateRechargePlanRequest {
    title: string
    amount: number
    coins: number
}

export interface EditRechargePlanRequest extends CreateRechargePlanRequest {}

const blogApi = {
    async index(request : RechargePlanListRequest) {
        const { data } = await axios.get<PaginatedResponse<RechargePlanBasic>>('/recharge-plans', {
            params: request,
        });
        return data;
    },

    async create(request: CreateRechargePlanRequest) {
        const { data } = await axios.post<GeneircIDResponse>('/recharge-plans', request);
        return data;
    },

    async edit(id: ID, request: EditRechargePlanRequest) {
        const { data } = await axios.patch<GenericStatusResponse>(`/recharge-plans/${id}`, request);
        return data;
    },

    async findById(id: ID) {
        const { data } = await axios.get<RechargePlanBasic>(`/recharge-plans/${id}`);
        return data;
    },


    async deleteById(id: ID) {
        const { data } = await axios.delete<GenericStatusResponse>(`/recharge-plans/${id}`);
        return data;
    },
}

export default blogApi;