import { Button, Form, Input, Space } from 'antd'
import { LoginRequest } from '../../api/auth'
import api from '../../api'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '../../shared/components/ErrorMessage';
import { Link } from '../../shared/components/Link';
import { useAuthContext } from '../context/AuthContext';

export const LoginForm = () => {

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const navigate = useNavigate();
  const { refresh } = useAuthContext();

  const login = async (data : LoginRequest) => {
    try {
      setSubmitting(true);
      await api.auth.login(data);
      refresh();
    } catch (e) {
      setError(e)
    } finally {
      setSubmitting(false);
    }
  }

  return <div>
    <Form<LoginRequest>
      layout='vertical'
      onFinish={login}
    >
      <ErrorMessage error={error}/>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'email is required' },
          { type: 'email', message: 'must be a valid email' }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: 'password is required' },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button
          style={{ width: '100%' }}
          loading={submitting}
          disabled={submitting}
          type="primary"
          size='large'
          htmlType="submit"
        >
          Login
        </Button>
      </Form.Item>

      <Form.Item>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Space direction='vertical'>
            {/* @ts-ignore */}
            <Link to="/auth/reset-password">
              Forgot Password?
            </Link>
          </Space>
        </div>
      </Form.Item>
    </Form>
  </div>
}