import axios from "./axios"
import { FeeBasic } from "./entities"
import { GenericStatusResponse } from "./types"

export interface EditFeeRequest {
    subscription: number
    trade: number
    paper_trade: number
    analyst_free_coins: number
    trader_free_coins: number
    refer_analyst_coins: number
    refer_trader_coins: number
}

const feesApi = {
    async find() {
        const { data } = await axios.get<FeeBasic|null>('/fees');
        return data;
    },

    async edit(request : EditFeeRequest) {
        const { data } = await axios.patch<GenericStatusResponse>('/fees', request);
        return data;
    }
}

export default feesApi;