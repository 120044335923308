import { UserBasic } from "../../api/entities"
import { Descriptions } from 'antd';
import { formatMoney, formatNumber } from "../../utils/common";
import { Link } from "react-router-dom";

export const UserBasicInfo = ({
    user
} : {
    user: UserBasic
}) => {
    const tradeHistoryParams = new URLSearchParams();
    tradeHistoryParams.set('user_id', `${user.id}`);

    return <Descriptions title='Basic Info'>
        <Descriptions.Item label="Name">
            { user.name }
        </Descriptions.Item>
        <Descriptions.Item label="Email">
            { user.email }
        </Descriptions.Item>
        <Descriptions.Item label="Role">
            { user.role }
        </Descriptions.Item>
        <Descriptions.Item label="Blocked">
            { user.blocked ? 'Yes' : 'No' }
        </Descriptions.Item>
        <Descriptions.Item label='Coin Balance'>
            { formatNumber(user.coin_balance) }
        </Descriptions.Item>
        <Descriptions.Item label="Trade History">
            <Link to={{ pathname: '/admin/trade-history', search: tradeHistoryParams.toString() }}>
                Trade History
            </Link>
        </Descriptions.Item>
    </Descriptions>
}