import { Space, Table, Tag } from 'antd';
import { UserMembership } from '../../api/entities';
import { DisplayMembershipStatus } from '../../api/enum-display';
import { formatDate } from '../../utils/common';

export const UserMemberships = ({
    memberships
} : {
    memberships: UserMembership[]
}) => {
    return <Table<UserMembership>
        dataSource={memberships}
        pagination={false}
        columns={[
            { key: 'id', dataIndex: 'id', title: 'ID' },
            { 
                key: 'status',
                title: 'Status',
                render: (_, entity) => DisplayMembershipStatus[entity.status],
            },
            { 
                key: 'services',
                title: 'Services',
                render: (_, entity) => {
                    return <Space>
                        {
                            entity.stocks && <Tag color='success'>
                                Stocks
                            </Tag>
                        }
                        {
                            entity.options && <Tag color='success'>
                                Options
                            </Tag>
                        }
                    </Space>
                },
            },
            {
                key: 'plan',
                title: 'Plan Name',
                render: (_, entity) => entity.membership_plan.name,
            },
            {
                key: 'signal_provider',
                title: 'Signal Provider',
                render: (_, entity) => `
                ${entity.membership_plan.signal_provider.name} \n
                @${entity.membership_plan.signal_provider.username}
                `,
            },
            {
                key: 'payment paused',
                title: 'Payment Paused',
                render: (_, entity) => entity.payment_collection_paused ? 'Yes' : 'No'
            },
            {
                key: 'cycles',
                title: 'Total Cycles',
                render: (_, entity) => {
                    return entity.billing_cycles === 0 ? 'Infinite' : entity.billing_cycles;
                },
            },
            {
                key: 'cycles_charged',
                dataIndex: 'billing_cycles_charged',
                title: 'Cycles Charged',
            },
            {
                key: 'cancelled_after_payment_failure',
                title: 'Cancelled after payment failure',
                render: (_, entity) => entity.cancelled_after_payment_failure ? 'Yes' : 'No'  
            },
            {
                key: 'created_at',
                title: 'Created At',
                render: (_, entity) => formatDate(entity.created_at)
            }
        ]}
    />
}