import { AccountBalanceRechargeStatus, AnalystSignupRequestStatus, BillingIntervalUnit, BrokerType, MembershipStatus, SignalProviderType, TradeHistoryAction, UserRole, YesNo } from "./enums";

export const DisplayUserRole : Record<UserRole, string> = {
    [UserRole.Admin]: 'Admin',
    [UserRole.Analyst]: 'Analyst',
    [UserRole.Trader]: 'Trader',
}

export const DisplayYesNo : Record<YesNo, string> = {
    [YesNo.Any]: 'Any',
    [YesNo.Yes]: 'Yes',
    [YesNo.No]: 'No'
}

export const DisplaySignalProviderType : Record<SignalProviderType, string> = {
    [SignalProviderType.Individual]: 'Individual'
}

export const DisplayBrokerType : Record<BrokerType, string> = {
    [BrokerType.Webull]: 'Webull',
    [BrokerType.Robinhood]: 'Robinhood',
    [BrokerType.TDA]: 'TDA',
}

export const DisplayMembershipStatus : Record<MembershipStatus, string> = {
    [MembershipStatus.Draft]: 'Draft',
    [MembershipStatus.Active]: 'Active',
    [MembershipStatus.Completed]: 'Completed',
    [MembershipStatus.Cancelled]: 'Cancelled',
}

export const DisplayBillingIntervalUnit: Record<BillingIntervalUnit, string> = {
    [BillingIntervalUnit.Month]: 'Month',
    [BillingIntervalUnit.Week]: 'Week',
    [BillingIntervalUnit.Year]: 'Year',
}

export const DisplayAccountBalanceRechargeStatus: Record<AccountBalanceRechargeStatus, string> = {
    [AccountBalanceRechargeStatus.Failed]: 'Failed',
    [AccountBalanceRechargeStatus.Pending]: 'Pending',
    [AccountBalanceRechargeStatus.Success]: 'Success',
}

export const DisplayTradeHistoryAction: Record<TradeHistoryAction, string> = {
    [TradeHistoryAction.CancelOrder]: 'Cancel Order',
    [TradeHistoryAction.ModifyOrder]: 'Modify Order',
    [TradeHistoryAction.PlaceOrder]: 'Place Order'
}

export const DisplayAnalystSignupRequestStatus : Record<AnalystSignupRequestStatus, string> = {
    [AnalystSignupRequestStatus.Pending]: 'Pending',
    [AnalystSignupRequestStatus.Approved]: 'Approved',
    [AnalystSignupRequestStatus.Rejected]: 'Rejected',
}