import { ProCard } from "@ant-design/pro-components";
import { Col, Row, Skeleton } from "antd";
import { useParams } from "react-router-dom"
import useSWR from "swr";
import api from "../../api";
import { AntPageContainer } from "../../shared/components/AntPageContainer";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { UserBasicInfo } from "../components/UserBasicInfo";
import { UserBrokerAccounts } from "../components/UserBrokerAccounts";
import { UserMemberships } from "../components/UserMemberships";
import { UserSignalProviders } from "../components/UserSignalProviders";

export const ViewUser = () => {
    const { id } = useParams();
    
    const {
        data: user,
        isLoading,
        error,
    } = useSWR(`/users/${id}}`, () => {
        return api.users.findById(id);
    });

    if (isLoading) {
        return <Skeleton/>
    }

    if (error) {
        return <ErrorMessage error={error} />
    }

    return <AntPageContainer>
        {
            user && <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ProCard bordered>
                        <UserBasicInfo user={user}/>
                    </ProCard>
                </Col>
                {
                    user.signal_providers && <Col span={24} lg={{ span: 12 }}>
                        <ProCard bordered title="Signal Providers">
                            <UserSignalProviders signalProviders={user.signal_providers}/>
                        </ProCard>
                    </Col>
                }
                {
                    user.broker_accounts && <Col span={24} lg={{ span: 12 }}>
                        <ProCard bordered title="Broker Accounts">
                            <UserBrokerAccounts brokerAccounts={user.broker_accounts}/>
                        </ProCard>
                    </Col>
                }
                {
                    user.memberships && <Col span={24}>
                        <ProCard bordered title="Memberships">
                            <UserMemberships memberships={user.memberships}/>
                        </ProCard>
                    </Col>
                }
            </Row>
        }
    </AntPageContainer>
}