export type TokenType = 'access' | 'refresh';

const KEY = 'auth';

export interface AuthInfo {
  access_token: string
  refresh_token: string
  access_token_expires: string // iso8601 datetime
  refresh_token_expires: string // iso8601 datetime
}

export const getAuthInfo = () => {
  const authInfo = localStorage.getItem(KEY);
  try {
    return authInfo !== null 
      ? JSON.parse(authInfo) as AuthInfo
      : null;
  } catch (e) {
    return null;
  }
}

export const setAuthInfo = (authInfo: AuthInfo) => {
  localStorage.setItem(KEY, JSON.stringify(authInfo));
}

export const clearAuthInfo = () => {
  localStorage.removeItem(KEY);
}