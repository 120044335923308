import { ProTable } from "@ant-design/pro-components"
import { Button, message, Popconfirm } from "antd"
import api from "../../api"
import { AnalystSignupRequest, AnalystSignupRequestListRequest } from "../../api/analyst-signup-requests"
import { DisplayAnalystSignupRequestStatus } from "../../api/enum-display"
import { AnalystSignupRequestStatus } from "../../api/enums"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { getErrorMessage } from "../../shared/components/ErrorMessage"

export const ListAnalystSignupRequests = () => {
    const [messageApi, contextHolder] = message.useMessage();

    return <AntPageContainer>
        {contextHolder}
        <ProTable<AnalystSignupRequest>
            headerTitle="Analyst Signup Request"
            cardBordered
            search={{
                labelWidth: 'auto',
                searchGutter: 8,
            }}
            options={{
                density: false,
                setting: false,
            }}
            form={{
                syncToUrl: true,
            }}
            columns={[
                {
                    key: 'id',
                    dataIndex: 'id',
                    title: 'ID',
                    hideInSearch: true,
                },
                {
                    key: 'name_or_email',
                    title: 'Name / Email',
                    hideInTable: true,
                },
                {
                    key: 'name',
                    title: 'Name',
                    dataIndex: 'name',
                    hideInSearch: true,
                },
                {
                    key: 'username',
                    title: 'Username',
                    dataIndex: 'username',
                    hideInSearch: true,
                },
                {
                    key: 'email',
                    title: 'Email',
                    dataIndex: 'email',
                    hideInSearch: true,
                },
                {
                    key: 'status',
                    dataIndex: 'status',
                    title: 'Status',
                    valueEnum: DisplayAnalystSignupRequestStatus,
                    valueType: 'select',
                },
                {
                    title: 'Created At',
                    key: 'created_at',
                    dataIndex: 'created_at',
                    hideInSearch: true,
                    valueType: 'date',
                },
                {
                    title: 'Actions',
                    key: 'actions',
                    valueType: 'option',
                    render: (_, entity, index, action) => {
                        if (entity.status !== AnalystSignupRequestStatus.Pending) {
                            return null;
                        }
                        return [
                            <Popconfirm
                                title='Approve'
                                okText='Yes'
                                cancelText='No'
                                onConfirm={async () => {
                                    try {
                                        await api.analystSignupRequests.approve(entity.id)
                                        action?.reload();
                                    } catch (e) {
                                        const errorMessage = getErrorMessage(e);
                                        messageApi.success(errorMessage);
                                    }
                                }}
                            >
                                <Button type="primary">
                                    Approve
                                </Button>
                            </Popconfirm>,
                            <Popconfirm
                                title='Reject'
                                okText='Yes'
                                cancelText='No'
                                onConfirm={async () => {
                                    try {
                                        await api.analystSignupRequests.reject(entity.id)
                                        action?.reload();
                                    } catch (e) {
                                        const errorMessage = getErrorMessage(e);
                                        messageApi.success(errorMessage);
                                    }
                                }}
                            >
                                <Button danger>
                                    Reject
                                </Button>
                            </Popconfirm>,
                        ]
                    }
                }
            ]}
            request={async (params, sort) => {
                const request = new AnalystSignupRequestListRequest();
                request.setPageParams(params);
                request.name_or_email = params.name_or_email;
                return await api.analystSignupRequests.index(request);
            }}
        />
    </AntPageContainer>
}