import { useHref, useLinkClickHandler } from "react-router-dom";
import { Typography } from 'antd';
import React from 'react';

// Sonmewhy the react router library removed
// as and component prop from their link component
// https://github.com/remix-run/react-router/pull/7998
export const Link = React.forwardRef(
  ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
    let href = useHref(to);
    let handleClick = useLinkClickHandler(to, { replace, state, target });

    return (
      <Typography.Link
        {...rest}
        href={href}
        onClick={event => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event);
          }
        }}
        ref={ref}
        target={target}
      />
    );
  }
);