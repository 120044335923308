import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { ProTable } from "@ant-design/pro-components"
import { Button, Popconfirm } from "antd"
import { Link } from "react-router-dom"
import api from "../../api"
import { BlogBasic } from "../../api/entities"
import { DisplayYesNo } from "../../api/enum-display"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { yesNoToBoolean } from "../../utils/common"
import { BlogListRequest } from "../../api/blog"
import { getErrorMessage } from "../../shared/components/ErrorMessage"
import { message } from "antd";

export const ListBlogs = () => {
    const [messageApi, contextHolder] = message.useMessage();
    return <AntPageContainer>
        { contextHolder }
        <ProTable<BlogBasic>
            headerTitle="Blogs"
            cardBordered
            search={{  
                labelWidth: 'auto',
                searchGutter: 8,
            }}
            options={{
                density: false,
                setting: false,
            }}
            form={{
                syncToUrl: true,
            }}
            toolBarRender={()=> [
                <Link to='create'>
                  <Button key="create" icon={<PlusOutlined/>} type="primary">
                    Create
                  </Button>
                </Link>,
              ]}
            columns={[
                {
                    key: 'id',
                    dataIndex: 'id',
                    title: 'ID',
                    hideInSearch: true,
                },
                {
                    key: 'title',
                    title: 'Title',
                    dataIndex: 'title',
                },
                {
                    title: 'Published',
                    dataIndex: 'published',
                    valueType: 'select',
                    valueEnum: DisplayYesNo,
                    render: (dom, entity, index, action, schema) => {
                        return entity.published ? 'Yes': 'No'
                    },
                },
                {
                    title: 'Created At',
                    key: 'created_at',
                    dataIndex: 'created_at',
                    hideInSearch: true,
                    valueType: 'date',
                },
                {
                    title: 'Published At',
                    key: 'published_at',
                    dataIndex: 'published_at',
                    hideInSearch: true,
                    valueType: 'date',
                },
                {
                    key: 'published_date',
                    title: 'Published At',
                    valueType: 'dateTimeRange',
                    hideInTable: true,
                    fieldProps: {
                        allowEmpty: [true, true],
                    }
                },
                {
                    title: 'Actions',
                    key: 'actions',
                    valueType: 'option',
                    render: (_, entity, index, action) => [
                        <Link to={`${entity.id}/edit`}>
                            <EditOutlined/>
                        </Link>,
                        <Popconfirm
                            title='Delete'
                            okText='Yes'
                            cancelText='No'
                            onConfirm={async () => {
                                try {
                                    await api.blog.deleteById(entity.id)
                                    action?.reload();
                                } catch (e) {
                                    const errorMessage = getErrorMessage(e);
                                    messageApi.success(errorMessage);
                                }
                            }}
                        >
                            <Button danger type="text">
                                <DeleteOutlined  />
                            </Button>
                        </Popconfirm>,
                    ]
                }
            ]}
            request={async (params, sort) => {
                const request = new BlogListRequest();
                request.setPageParams(params);
                request.published = yesNoToBoolean(params.published)
                request.title = params.title;
                if (params.published_date && Array.isArray(params.published_date) && params.published_date.length === 2) {
                    request.min_published_at = params.published_date[0] || undefined;
                    request.max_published_at = params.published_date[1] || undefined;
                }
                return await api.blog.index(request);
            }}
        />
    </AntPageContainer>
}