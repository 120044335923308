export interface GenericStatusResponse {
  success: boolean
  message: string | null
}

export interface GeneircIDResponse {
  id: number
}

export interface PaginatedResponse<T> {
  total: number
  page: number
  per_page: number
  pages?: number
  data: T[]
}

export type ID = string | number | undefined;

export class ListRequest {
  page: number = 1
  per_page: number = 10

  setPageParams({ 
    pageSize, 
    current 
  }: {
    pageSize?: number;
    current?: number
  }) {
    if (current) this.page = current;
    if (pageSize) this.per_page = pageSize;
  }
}