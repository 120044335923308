import { Typography } from 'antd';
import { ResetPasswordForm, ResetPasswordFormScreen } from '../components/ResetPasswordForm';
import { useState } from 'react';
const { Title } = Typography;

const screenTitle : Record<ResetPasswordFormScreen, string> = {
  "send-otp": 'Reset Your Password',
  "change-password": 'Enter new password',
  "verify-otp": 'Enter OTP'
}

export const ResetPassword = () => {
  const [title, setTitle] = useState<string>('Reset Your Password');

  return <div>
    <Title style={{ textAlign: 'center' }} level={1}> {title} </Title>
    <ResetPasswordForm 
      onScreenChange={(screen) => {setTitle(screenTitle[screen]);}}
    />
  </div>
}