import { ProCard, ProForm } from "@ant-design/pro-components";
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import api from "../../api";
import { SignalProviderType } from "../../api/enums";
import { EditSignalProviderRequest } from "../../api/signal-providers";
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { SignalProviderFormFields } from "../components/SignalProviderFormFields";

interface EditSignalProviderRequestForm extends EditSignalProviderRequest {
    owner: { label: string, value: number },
    type: SignalProviderType,
}

export const EditSignalProvider = () => {
    const { id } = useParams();
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();

    return <AntPageContainer>
        <ProCard bordered>
            <ProForm<EditSignalProviderRequestForm>
                request={async () => {
                    const signalProvider = await api.signalProviders.findById(id);

                    const data : EditSignalProviderRequestForm = {
                        name: signalProvider.name,
                        bio: signalProvider.bio || undefined,
                        username: signalProvider.username || '',
                        owner: { 
                            label: signalProvider.owner.name + ' ' + signalProvider.owner.email, 
                            value: signalProvider.owner.id 
                        },
                        type: signalProvider.type,
                    };

                    return data;
                }}
                onFinish={async (values) => {
                    const request : EditSignalProviderRequest = {
                        name: values.name,
                        bio: values.bio,
                        username: values.username
                    }

                    try {

                        await api.signalProviders.edit(id, request);
                        message.success("Success")
                        navigate("/admin/signal-providers");
                        
                    } catch (e) {
                        setError(e);
                    }

                }}
            >
                <SignalProviderFormFields edit={true} />
            </ProForm>
        </ProCard>
    </AntPageContainer>
}