import { ProCard, ProForm, ProFormCheckbox, ProFormDigit, ProFormText } from "@ant-design/pro-components"
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { AdminEditCandeFiMembershipPlanRequest } from "../../api/candefi-membership-plans";

export const EditCandeFiMembershipPlan = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();
    const { id } = useParams();

    return <AntPageContainer>
        <ProCard
            bordered
        >
            <ProForm<AdminEditCandeFiMembershipPlanRequest>
                grid
                colProps={{ span: 12 }}
                params={{ id }}
                request={async (params : Record<string, string>) => {
                    try {
                        const plan = await api.candeFiMembershipPlans.findById(params.id);
                        return plan;
                    } catch (e) {
                        console.log(e);
                        navigate('/admin/candefi-membership-plans');
                    }
                }}
                onFinish={async (data) => {

                    try {

                        // default value is undefined
                        // but we want false
                        data.can_autotrade = data.can_autotrade || false;
                        data.can_buy_extra_coins = data.can_buy_extra_coins || false;

                        await api.candeFiMembershipPlans.edit(id, data);
                        message.success("Success")
                        navigate("/admin/candefi-membership-plans");

                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <ProFormText
                    required
                    label="Name"
                    colProps={{ span: 12 }}
                    name="name"
                    rules={[
                        { required: true, message: 'name is required' },
                    ]}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 24 }}
                    name="monthly_coins"
                    label="Monthly Coins"
                    min={0}
                    rules={[
                        { required: true, message: 'monthly coins is required' },
                    ]}
                />
                <ProFormCheckbox
                    colProps={{ span: 12 }}
                    name="can_autotrade"
                    label="Can Autotrade"
                    fieldProps={{
                        defaultChecked: false,
                    }}
                />
                <ProFormCheckbox
                    colProps={{ span: 12 }}
                    name="can_buy_extra_coins"
                    label="Can Buy Extra Coins"
                    fieldProps={{
                        defaultChecked: false,
                    }}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 24 }}
                    name="max_autotrade_bots"
                    label="Max Autotrade Bots"
                    min={0}
                    rules={[
                        { required: true, message: 'max autotrade bots is required' },
                    ]}
                />
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}