// api base url
export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL_AUTH = process.env.REACT_APP_AUTH_API_URL;

// auth related routes
export const LOGIN_PATH = "/auth/login";
export const REFRESH_PATH = "/auth/refresh-token";
export const ME_PATH = "/auth/me"

console.log("base auth url", BASE_URL_AUTH)
console.log("base url", BASE_URL)