import { createContext, ReactNode, useContext } from 'react';
import { CurrentUserInfo } from '../../api/auth';
import useSWR from 'swr';
import api from '../../api';

export interface AuthContextState {
  user: CurrentUserInfo | null,
  loading: boolean,
  refresh: () => void,
}

const AuthContext = createContext<AuthContextState>({
  loading: true,
  user: null,
  refresh: () => {},
});

export const AuthContextProvider = ({
  children,
} : {
  children: ReactNode,
}) => {
  const {
    data: user,
    error,
    mutate,
    isLoading,
  } = useSWR('/auth/me', () => {
    return api.auth.me();
  });

  const state : AuthContextState = {
    user: user || null,
    loading: !user && !error && isLoading,
    refresh: () => mutate(undefined, {
      revalidate: true,
    }),
  }

  return <AuthContext.Provider value={state}>
    {children}
  </AuthContext.Provider>;
}

export const useAuthContext = () => useContext(AuthContext);