import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserRole } from "../../api/enums";
import { useAuthContext } from "../context/AuthContext";

export const Guest = ({ 
  children 
} : {
  children: ReactNode,
}) => {

  const location = useLocation();

  const {
    loading,
    user,
  } = useAuthContext();

  // when user info is being fetched
  // render nothing
  if (loading) {
    console.log("loading")
    return null;
  }

  // when the user is not logged in
  // redirect the user to the previous page
  // or dashboard
  if (user != null && user.role === UserRole.Admin) {
    console.log("navigate")
    return <Navigate 
      to={
        location?.state?.from?.pathname || '/admin/dashboard'
      }
    />
  }

  console.log("children")
  // show component
  return <>
    { children }
  </>
}