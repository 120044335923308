import { EditOutlined, PlusOutlined } from "@ant-design/icons"
import { ProTable } from "@ant-design/pro-components"
import { Button, Popconfirm, Space, Tag } from "antd"
import { Link } from "react-router-dom"
import api from "../../api"
import { CandeFiMembershipPlanBasic, MembershipPlanListItem } from "../../api/entities"
import { DisplaySignalProviderType, DisplayYesNo } from "../../api/enum-display"
import { ListMembershipPlanRequest } from "../../api/membership-plans"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { formatMoney, yesNoToBoolean } from "../../utils/common"

export const ListCandefiMembershipPlans = () => {
    return <AntPageContainer>
        <ProTable<CandeFiMembershipPlanBasic>
            headerTitle="CandeFi Membership Plans"
            cardBordered
            search={false}
            options={{
                density: false,
                setting: false,
            }}
            pagination={false}
            form={{
                syncToUrl: true,
            }}
            toolBarRender={() => [
                <Link to='create'>
                    <Button key="create" icon={<PlusOutlined />} type="primary">
                        Create
                    </Button>
                </Link>,
            ]}
            request={async (params, sort) => {
                const data = await api.candeFiMembershipPlans.listAll();
                return { data }
            }}
            columns={[
                { key: 'id', title: 'ID', dataIndex: 'id', hideInSearch: true },
                { key: 'name', dataIndex: 'name', title: 'Name', hideInSearch: true },
                {
                    key: 'billing_interval',
                    title: 'Billing Interval',
                    render: (_, row) => {
                        return `${row.billing_interval}`
                    },
                    hideInSearch: true,
                },
                {
                    key: 'price',
                    title: 'Price',
                    render: (_, row) => {
                        return `${formatMoney(row.price || 0)}`;
                    },
                    hideInSearch: true,
                },
                {
                    title: 'Created At',
                    key: 'created_at',
                    dataIndex: 'created_at',
                    hideInSearch: true,
                    valueType: 'date',
                },
                {
                    key: 'actions',
                    valueType: 'option',
                    title: 'Actions',
                    render: (_, entity, index, actions) => {
                        return <Space>
                            <Link to={`${entity.id}/edit`}>
                                Edit
                            </Link>
                            {
                                !entity.is_free && (
                                    <Link to={`${entity.id}/edit-price`}>
                                        Edit Price
                                    </Link>
                                )
                            }
                            <Popconfirm
                                title='Are You Sure?'
                                onConfirm={async () => {
                                    await api.candeFiMembershipPlans.delete(entity.id)
                                    actions?.reload();
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button size="small" type="link" danger>
                                    Delete Plan
                                </Button>
                            </Popconfirm>
                        </Space>
                    },
                    hideInSearch: true,
                },
            ]}
        />
    </AntPageContainer>
}