import axios from './axios';
import { SignalProviderListItem, SignalProviderWithDetails } from './entities';
import { SignalProviderType } from './enums';
import { GeneircIDResponse, GenericStatusResponse, ID, ListRequest, PaginatedResponse } from './types';

export class ListSignalProvidersRequest extends ListRequest {
   name?: string
   username?: string
   owner_id?: number
   owner_name?: string
   owner_email?: string
   owner_name_or_email?: string
   type?: SignalProviderType 
}

export interface EditSignalProviderRequest {
    name: string
    username: string
    bio?: string
}

export interface CreateSignalProviderRequest extends EditSignalProviderRequest {
    type: SignalProviderType
    owner_id: number
}

const signalProvidersApi = {
    async list(request : ListSignalProvidersRequest) {
        const { data }  = await axios.get<PaginatedResponse<SignalProviderListItem>>(
            '/signal-providers', 
            { params: request }
        );
        return data;
    },

    async findById(id: ID) {
        const { data } = await axios.get<SignalProviderWithDetails>(`/signal-providers/${id}`);
        return data;
    },

    async create(request : CreateSignalProviderRequest) {
        const { data } = await axios.post<GeneircIDResponse>(`/signal-providers`, request);
        return data;
    },

    async edit(id: ID, request : EditSignalProviderRequest) {
        const { data } = await axios.patch<GenericStatusResponse>(`/signal-providers/${id}`, request);
        return data;
    } 
}

export default signalProvidersApi;