import axios from 'axios';
import refreshAccessToken from './interceptors/refresh-access-token';
import { BASE_URL } from './constants';
import transformError from './errors';
// import saveToken from './interceptors/save-token';
import attachToken from './interceptors/attach-token';

// axios instance for making api calls
const instance = axios.create({
    baseURL: BASE_URL
});

// before making api call refresh access token token has expired
instance.interceptors.request.use(refreshAccessToken);

// attach token to request
instance.interceptors.request.use(attachToken);

// save token
// instance.interceptors.response.use(saveToken);

// transforms errors
instance.interceptors.response.use(null, transformError);

export default instance;