import { ProCard, ProForm } from "@ant-design/pro-components"
import { useState } from "react"
import api from "../../api"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage"
import { BlogFormFields } from "../components/blog-form-fields"
import { message } from 'antd';
import { useNavigate } from "react-router-dom"
import { CreateBlogRequest } from "../../api/blog"

export const CreateBlog = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();


    return <AntPageContainer>
        <ProCard bordered>
            <ProForm<CreateBlogRequest>
                grid
                colProps={{ span: 12 }}
                onFinish={async (data) => {
                    data.published = data.published || false;

                    try {
                        await api.blog.create(data);
                        message.success("Success")
                        navigate("/admin/blogs");
                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <BlogFormFields />
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}