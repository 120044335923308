import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import { useState } from "react";
import useSWR from "swr";
import api from "../../../api";
import { ListSignalProvidersRequest } from "../../../api/signal-providers";

interface ProformSignalProviderSelectSelectProps extends ProFormSelectProps {
    filter?: ListSignalProvidersRequest
}

export const ProFormSignalProviderSelect = (props : ProformSignalProviderSelectSelectProps) => {

    const { filter, ...restProps } = props;
    const [searchValue, setSearchValue] = useState<string | null>(null);

    const {
        data: options,
        isLoading,
    } = useSWR(
        !!searchValue ? `/api/signal_providers/?username=${searchValue}` : '',
        async () => {
            const request = new ListSignalProvidersRequest();

            // copy fields from default filters
            if (filter) {
                request.name = filter.name;
                request.owner_email = filter.owner_email;
                request.owner_id = filter.owner_id;
                request.owner_name = filter.owner_name;
                request.type = filter.type;
                request.page = filter.page;
                request.per_page = filter.per_page;
            }

            // override name or email
            request.username = searchValue || undefined;

            const { data }  = await api.signalProviders.list(request);
            return data.map(data => ({
                label: `@${data.username}`,
                value: data.id,
            }))
        }
    );

    return <ProFormSelect
        fieldProps={{
            showSearch: true,
            onSearch: (value) => setSearchValue(value || null),
            placeholder: 'Search by username',
            loading: isLoading,
            labelInValue: true,
        }}
        options={options}
        {...restProps}
    />
}