import { ProFormField, ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-components"
import { DisplaySignalProviderType } from "../../api/enum-display"
import { UserRole } from "../../api/enums";
import { UsersListRequest } from "../../api/users";
import { ProFormUserSelect } from "../../shared/components/inputs/ProFormUserSelect"

export const SignalProviderFormFields = ({
    edit = false
} : {
    edit?: boolean
}) => {

    const userSearchRequest = new UsersListRequest();
    userSearchRequest.role = UserRole.Analyst;

    return <>
        <ProFormText
            required
            name="name"
            label="Name"
            rules={[
                { required: true, message: 'name is required' },
            ]}
            colProps={{ span: 12 }}
        />
        <ProFormText
            required
            name="username"
            label="Username"
            rules={[
                { required: true, message: 'username is required' },
            ]}
            colProps={{ span: 12 }}
        />
        {
            !edit && <ProFormSelect
                required
                name="type"
                label="Type"
                valueEnum={DisplaySignalProviderType}
                colProps={{ span: 24 }}
            />
        }
        <ProFormTextArea
            name="bio"
            label="Bio"
        />
        <ProFormUserSelect
            name="owner"
            label="Owner / Analyst"
            disabled={edit}
            rules={[
                { required: true, message: 'owner is required' }
            ]}
            filter={userSearchRequest}
        />    
    </>
}