import axios from "./axios";
import { TradeHistoryListItem } from "./entities";
import { TradeHistoryAction } from "./enums";
import { ListRequest, PaginatedResponse } from "./types";

export class ListTradeHistoryRequest extends ListRequest {
    user_id?: number
    user_name?: number
    user_email?: number
    action?: TradeHistoryAction
    min_date?: string
    max_date?: string
}

const tradeHistoryApi = {
    async index(request : ListTradeHistoryRequest) {
        const { data } = await axios.get<PaginatedResponse<TradeHistoryListItem>>(
            '/trade-history', 
            { params: request }
        );
        return data;
    }
}

export default tradeHistoryApi;