import { BillingIntervalUnit, SignalProviderType } from "./enums";
import { GeneircIDResponse, GenericStatusResponse, ID, ListRequest, PaginatedResponse } from "./types";
import axios from './axios';
import { MembershipPlanListItem, MembershipPlanWithStats } from "./entities";

export class ListMembershipPlanRequest extends ListRequest {
    user_email?: string
    user_name?: string
    user_id?: number
    signal_provider_type?: SignalProviderType
    signal_provider_name?: string
    signal_provider_id?: number
    active?: boolean
    stocks?: boolean
    options?: boolean
}

export interface EditMembershipPlanRequest {
    active: boolean
}

export interface CreateMembershipPlanRequest {
    name: string
    signal_provider_id: number
    billing_interval: number
    billing_interval_unit: BillingIntervalUnit
    billing_cycles: number
    amount: number
    stocks: boolean
    options: boolean
}

const membershipPlansApi = {
    async index(request: ListMembershipPlanRequest) {
        const { data } = await axios.get<PaginatedResponse<MembershipPlanListItem>>('/membership-plans', { params: request });
        return data;
    },

    async create(request: CreateMembershipPlanRequest) {
        const { data } = await axios.post<GeneircIDResponse>('/membership-plans', request);
        return data;
    },

    async edit(id: ID, request: EditMembershipPlanRequest) {
        const { data } = await axios.patch<GenericStatusResponse>(`/membership-plans/${id}`, request);
        return data;
    },

    async findById(id: ID) {
        const { data } = await axios.get<MembershipPlanWithStats>(`/memberships-plans/${id}`);
        return data;
    }
}

export default membershipPlansApi;