import axios from "./axios";

export interface ImpersonateUserRequest {
  user_id: number
}

export interface ImpersonateUserResponse {
  token: string
}

const impersonateApi = {
  async impersonate(request : ImpersonateUserRequest) {

    const { data } = await axios.post<ImpersonateUserResponse>('/impersonate-user', request);
    return data;
  },
}

export default impersonateApi;