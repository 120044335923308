import { ProCard, ProForm } from "@ant-design/pro-components"
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { RechargePlansFormFields } from "../components/recharge-plans-form-fields";
import { EditRechargePlanRequest } from "../../api/recharge-plans";

export const EditRechargePlan = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();
    const { id } = useParams();

    return <AntPageContainer>
        <ProCard
            bordered
        >
            <ProForm<EditRechargePlanRequest>
                grid
                colProps={{ span: 12 }}
                params={{ id }}
                request={async (params : Record<string, string>) => {
                    try {
                        const plan = await api.rechargePlans.findById(params.id);
                        return plan as EditRechargePlanRequest;
                    } catch (e) {
                        navigate('/admin/recharge-plans');
                    }
                }}
                onFinish={async (data) => {

                    try {

                        await api.rechargePlans.edit(id, data);
                        message.success("Success")
                        navigate("/admin/recharge-plans");

                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <RechargePlansFormFields />
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}