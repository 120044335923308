import { EditOutlined, PlusOutlined } from "@ant-design/icons"
import { ProTable } from "@ant-design/pro-components"
import { Button, Popconfirm, Space, Tag } from "antd"
import { Link } from "react-router-dom"
import api from "../../api"
import { MembershipPlanListItem } from "../../api/entities"
import { DisplaySignalProviderType, DisplayYesNo } from "../../api/enum-display"
import { ListMembershipPlanRequest } from "../../api/membership-plans"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { formatMoney, yesNoToBoolean } from "../../utils/common"

export const ListMembershipPlan = () => {
    return <AntPageContainer>
        <ProTable<MembershipPlanListItem>
            headerTitle="Membership Plans"
            cardBordered
            search={{
                labelWidth: 'auto',
                searchGutter: 8,
            }}
            options={{
                density: false,
                setting: false,
            }}
            form={{
                syncToUrl: true,
            }}
            toolBarRender={() => [
                <Link to='create'>
                    <Button key="create" icon={<PlusOutlined />} type="primary">
                        Create
                    </Button>
                </Link>,
            ]}
            request={async (params, sort) => {
                const request = new ListMembershipPlanRequest();
                request.setPageParams(params);
                request.active = yesNoToBoolean(params.active);
                request.stocks = params.stocks;
                request.options = params.options;
                request.signal_provider_id = params.signal_provider_id;
                request.signal_provider_type = params.signal_provider_type;
                request.signal_provider_name = params.signal_provider_name;
                request.user_id = params.user_id;
                request.user_name = params.user_name;
                request.user_email = params.user_email;
                return api.membershipPlans.index(request);
            }}
            columns={[
                { key: 'id', title: 'ID', dataIndex: 'id', hideInSearch: true },
                { key: 'name', dataIndex: 'name', title: 'Name', hideInSearch: true },
                { 
                    key: 'options', 
                    dataIndex: 'options', 
                    title: 'Options', 
                    valueType: 'switch',
                    hideInTable: true, 
                },
                { 
                    key: 'stocks', 
                    dataIndex: 'stocks', 
                    title: 'Stocks', 
                    valueType: 'switch',
                    hideInTable: true,
                },
                { 
                    key: 'servicesDisplay', 
                    title: 'Services', 
                    hideInSearch: true,
                    render: (_, row) => {
                        return <Space>
                            <Tag color={row.stocks ? 'success' : 'default'}>
                                Stocks
                            </Tag>
                            <Tag color={row.options ? 'success' : 'default'}>
                                Options
                            </Tag>
                        </Space>
                    }
                },
                {
                    key: 'amount',
                    title: 'Amount',
                    render: (_, row) => formatMoney(row.amount || ''),
                    hideInSearch: true
                },
                {
                    key: 'user',
                    dataIndex: ['signal_provider', 'owner', 'id'],
                    title: 'User',
                    render: (_, row) => {
                        const user = row.signal_provider.owner;
                        return <Link to={`/admin/users/${user.id}/view`}>
                            {user.name} ({user.email})
                        </Link>;
                    },
                    hideInSearch: true,
                },
                {
                    key: 'signal_provider',
                    dataIndex: ['signal_provider', 'id'],
                    title: 'Signal Provider',
                    render: (_, row) => {
                        const provider = row.signal_provider;
                        return <Link to={`/admin/signal-providers/${provider.id}/view`}>
                            {provider.name} <br/>
                            @{provider.username}
                        </Link>
                    },
                    hideInSearch: true,
                },
                {
                    key: 'billing_interval',
                    title: 'Billing Interval',
                    render: (_, row) => {
                        return `${row.billing_interval} ${row.billing_interval_unit}`
                    },
                    hideInSearch: true,
                },
                {
                    key: 'billing_cycles',
                    title: 'Billing Cycles',
                    hideInSearch: true,
                    render: (_, row) => {
                        return row.billing_cycles == 0 ? 'Infinite' : row.billing_cycles;
                    },
                },
                {
                    title: 'Active',
                    dataIndex: 'active',
                    valueType: 'select',
                    valueEnum: DisplayYesNo,
                    render: (_, entity) => {
                        return entity.active ? 'Yes': 'No'
                    },
                },
                {
                    title: 'Created At',
                    key: 'created_at',
                    dataIndex: 'created_at',
                    hideInSearch: true,
                    valueType: 'date',
                },
                {
                    key: 'actions',
                    valueType: 'option',
                    title: 'Actions',
                    render: (_, row, index, actions) => [
                        <Popconfirm 
                            title={`${row.active ? 'Deactivate?' : 'Reactivate?'}`}
                            onConfirm={async () => {
                                await api.membershipPlans.edit(row.id, {
                                    active: !row.active,
                                })
                                actions?.reload();
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>
                                {
                                    row.active ? 'Deactivate' : 'Reactivate'
                                }
                            </Button>
                        </Popconfirm>
                    ],
                    hideInSearch: true,
                },
                { 
                    key: 'signal_provider_id', 
                    title: 'Signal Provider Id',
                    dataIndex: 'signal_provider_id',
                    hideInTable: true,
                    valueType: 'digit',
                },
                { 
                    key: 'signal_provider_type', 
                    title: 'Signal Provider Type',
                    dataIndex: 'signal_provider_type',
                    valueEnum: DisplaySignalProviderType,
                    hideInTable: true,
                },
                { 
                    key: 'signal_provider_name', 
                    title: 'Signal Provider Name',
                    dataIndex: 'signal_provider_name',
                    hideInTable: true,
                },
                { 
                    key: 'user_id', 
                    title: 'User Id',
                    dataIndex: 'user_id',
                    hideInTable: true,
                    valueType: 'digit',
                },
                { 
                    key: 'user_name', 
                    title: 'User Name',
                    dataIndex: 'user_name',
                    hideInTable: true,
                },
                { 
                    key: 'user_email', 
                    title: 'User Email',
                    dataIndex: 'user_email',
                    hideInTable: true,
                },
            ]}
        />
    </AntPageContainer>
}