import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import { useState } from "react";
import useSWR from "swr";
import api from "../../../api";
import { UsersListRequest } from "../../../api/users";

interface ProformUserSelectProps extends ProFormSelectProps {
    filter?: UsersListRequest
}

export const ProFormUserSelect = (props : ProformUserSelectProps) => {

    const { filter, ...restProps } = props;
    const [searchValue, setSearchValue] = useState<string | null>(null);

    const {
        data: options,
        isLoading,
    } = useSWR(
        !!searchValue ? `/api/users/?name_or_email=${searchValue}` : '',
        async () => {
            const request = new UsersListRequest();

            // copy fields from default filters
            if (filter) {
                request.blocked = filter.blocked;
                request.email = filter.email;
                request.name = filter.email;
                request.name_or_email = filter.name_or_email;
                request.page = filter.page;
                request.per_page = filter.per_page;
                request.role = filter.role;
            }

            // override name or email
            request.name_or_email = searchValue || undefined;

            const { data }  = await api.users.index(request);
            return data.map(data => ({
                label: data.name + ' ' + data.email,
                value: data.id,
            }))
        }
    );

    return <ProFormSelect
        fieldProps={{
            showSearch: true,
            onSearch: (value) => setSearchValue(value || null),
            loading: isLoading,
            labelInValue: true,
        }}
        options={options}
        {...restProps}
    />
}