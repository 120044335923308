import { PercentageOutlined } from "@ant-design/icons"
import { ProCard, ProForm, ProFormDigit, ProFormMoney } from "@ant-design/pro-components"
import { message } from "antd"
import api from "../../api"
import { EditFeeRequest } from "../../api/fees"
import { AntPageContainer } from "../../shared/components/AntPageContainer"

export const EditFees = () => {
    return <AntPageContainer>
        <ProCard bordered>
            <ProForm<EditFeeRequest>
                grid
                request={async () => {
                    const fees = await api.fees.find();
                    return fees || { 
                        subscription: 0,
                        trade: 0,
                        paper_trade: 0,
                        analyst_free_coins: 0,
                        trader_free_coins: 0,
                        refer_analyst_coins: 0,
                        refer_trader_coins: 0,
                    };
                }}
                onFinish={async (values) => {
                    await api.fees.edit(values);
                    message.success("Edited Successfully");
                }}
            >
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="subscription"
                    label="Subscription"
                    min={0}
                    max={100}
                    fieldProps={{
                        addonAfter: <PercentageOutlined/>,
                        precision: 2
                    }}
                    rules={[
                        { required: true, message: 'required' },
                    ]}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="analyst_free_coins"
                    label="Analyst Free Coins"
                    fieldProps={{
                        precision: 0,
                    }}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="trader_free_coins"
                    label="Trader Free Coins"
                    fieldProps={{
                        precision: 0,
                    }}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="trade"
                    label="Trade (Per Trade Coins)"
                    fieldProps={{
                        precision: 2,
                    }}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="paper_trade"
                    label="Paper Trade (Per Paper Trade Coins)"
                    fieldProps={{
                        precision: 2,
                    }}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="refer_analyst_coins"
                    label="Refer Analyst Coins"
                    fieldProps={{
                        precision: 0,
                    }}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 12 }}
                    name="refer_trader_coins"
                    label="Refer Trader Coins"
                    fieldProps={{
                        precision: 0,
                    }}
                />
            </ProForm>
        </ProCard>
    </AntPageContainer>
}