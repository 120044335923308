import { InternalAxiosRequestConfig } from 'axios';
import { getAuthInfo } from '../utils/token-storage';

export default async function attachToken(config : InternalAxiosRequestConfig<any> ) {
    // get access token expiry date
    let authInfo = getAuthInfo();
    let accessToken = authInfo?.access_token || null;

    if (accessToken) {
      // @ts-ignore
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return config;
};