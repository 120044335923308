import { EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons"
import { ProTable } from "@ant-design/pro-components"
import { Button } from "antd"
import { Link } from "react-router-dom"
import api from "../../api"
import { SignalProviderListItem } from "../../api/entities"
import { DisplaySignalProviderType } from "../../api/enum-display"
import { ListSignalProvidersRequest } from "../../api/signal-providers"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { formatDate } from "../../utils/common"

export const ListSignalProviders = () => {
    return <AntPageContainer>
        <ProTable<SignalProviderListItem>
            headerTitle="Signal Provider"
            cardBordered
            search={{  
                labelWidth: 'auto',
                searchGutter: 8,
            }}
            options={{
                density: false,
                setting: false,
            }}
            form={{
                syncToUrl: true,
            }}
            toolBarRender={()=> [
                <Link to='create'>
                  <Button key="create" icon={<PlusOutlined/>} type="primary">
                    Create
                  </Button>
                </Link>,
            ]}
            request={(params, sort, filter) => {
                const request = new ListSignalProvidersRequest();
                request.setPageParams(params);
                request.name = params.name;
                request.username = params.username;
                request.owner_id = params.owner_id;
                request.owner_name = params.owner_name;
                request.type = params.type;
                return api.signalProviders.list(request);
            }}
            columns={[
                { key: 'id', title: 'ID', dataIndex: 'id', hideInSearch: true },
                { key: 'name', title: 'Name', dataIndex: 'name' },
                { 
                    key: 'username', 
                    title: 'Username', 
                    dataIndex: 'username',
                    render: (_, provider) => `@${provider.username}`
                },
                { key: 'owner_id', title: 'User Id', hideInTable: true, valueType: 'digit' },
                { key: 'owner_name', title: 'User Name', dataIndex: [ 'owner', 'name' ] },
                { key: 'owner_email', title: 'User Email', dataIndex: [ 'owner', 'email' ] },
                { key: 'type', title: 'Type', dataIndex: 'type', valueEnum: DisplaySignalProviderType },
                { 
                    key: 'createdAt', 
                    title: 'Created At', 
                    dataIndex: 'created_at', 
                    hideInSearch: true,
                    render: (_, entity) => formatDate(entity.created_at),
                },
                {
                    key: 'actions',
                    valueType: 'option',
                    title: 'Actions',
                    render: (_, entity) => [
                        <Link to={`${entity.id}/edit`}>
                            <EditOutlined />
                        </Link>,
                        <Link to={`${entity.id}/view`}>
                            <EyeOutlined />
                        </Link>
                    ]
                }
            ]}
        />
    </AntPageContainer>
}