import axios from "./auth-axios";
import { UserRole } from "./enums";
import { GenericStatusResponse } from "./types";

export interface LoginRequest {
  email: string
  password: string
  roles?: UserRole[],
}

export interface LoginResponse {
  access_token: string
  refresh_token: string
  access_token_expires: string // iso8601 datetime
  refresh_token_expires: string // iso8601 datetime
}

export interface SignupRequest {
  name: string
  email: string
  password: string
}

export interface SendPasswordResetOTPRequest {
  email: string
}

export interface VerifyPasswordResetOTPRequest {
  email: string
  otp: string
}

export interface VerifyPasswordResetOTPResponse {
  token: string
}

export interface PasswordResetRequest {
  token: string
  password: string
}

export interface CurrentUserInfo {
  name: string
  email: string
  role: UserRole,
  stripe_account: {
    id: string
  }
}

const loginApi = {
  async login(request : LoginRequest) {
    // only admin can login
    request.roles = [ UserRole.Admin ]
    const { data } = await axios.post<LoginResponse>('/auth/login', request);
    return data;
  },

  async signup(request : SignupRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/auth/signup', request);
    return data;
  },

  async sendPasswordResetOtp(request: SendPasswordResetOTPRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/auth/password-reset/send-otp', request);
    return data;
  },

  async verifyPasswordResetOtp(request: VerifyPasswordResetOTPRequest) {
    const { data } = await axios.post<VerifyPasswordResetOTPResponse>('/auth/password-reset/verify-otp', request);
    return data;
  },

  async resetPassword(request: PasswordResetRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/auth/password-reset', request);
    return data;
  },

  async logout() {
    const { data } = await axios.delete<GenericStatusResponse>('/auth/logout');
    return data;
  },

  async me() {
    const { data } = await axios.get<CurrentUserInfo>('/auth/me');
    return data;
  }
}

export default loginApi;