import { App } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { SendOTPForm } from "./SendOTPForm";
import { VerifyOTPForm } from "./VerifyOTPForm";

export type ResetPasswordFormScreen = 'send-otp' | 'verify-otp' | 'change-password';

export interface ResetPasswordFormProps {
  onScreenChange: (screen : ResetPasswordFormScreen) => void | Promise<void>
}

export const ResetPasswordForm = ({
  onScreenChange,
} : ResetPasswordFormProps) => {

  const [screen, setScreen] = useState<ResetPasswordFormScreen>('send-otp');
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const navigate = useNavigate();
  const message = App.useApp().message;
  
  const onOTPSent = (email : string) => {
    setEmail(email);
    setScreen('verify-otp');
  }

  const onOTPVerified = (token: string) => {
    setToken(token);
    setScreen('change-password');
  }

  const onResend = () => {
    setScreen('send-otp');
  }

  const onSuccess = () => {
    message.success('Password Reset')
    navigate('/auth/login');
  }

  // notify parent
  useEffect(() => {
    onScreenChange(screen);
  }, [screen, onScreenChange]);
  
  return <div>
    {
      screen === 'send-otp' && <SendOTPForm onSuccess={onOTPSent}/>
    }
    {
      screen === 'verify-otp' && <VerifyOTPForm
        email={email}
        onSuccess={onOTPVerified}
        onResend={onResend}
      />
    }
    {
      screen === 'change-password' && <ChangePasswordForm
        token={token}
        onSuccess={onSuccess}
      />
    }
  </div>
}
