import { BlogBasic, BlogWithContent } from "./entities"
import { GeneircIDResponse, GenericStatusResponse, ID, ListRequest, PaginatedResponse } from "./types"
import axios from './axios';

export class BlogListRequest extends ListRequest {
    title?: string
    published?: boolean
    min_published_at?: string
    max_published_at?: string
}

export interface CreateBlogRequest {
    slug: string
    title: string
    excerpt: string
    published: boolean
    content: string
    cover_image: string
}

export interface EditBlogRequest extends CreateBlogRequest {}

const blogApi = {
    async index(request : BlogListRequest) {
        const { data } = await axios.get<PaginatedResponse<BlogBasic>>('/blog', {
            params: request,
        });
        return data;
    },

    async create(request: CreateBlogRequest) {
        const { data } = await axios.post<GeneircIDResponse>('/blog', request);
        return data;
    },

    async edit(id: ID, request: EditBlogRequest) {
        const { data } = await axios.patch<GenericStatusResponse>(`/blog/${id}`, request);
        return data;
    },

    async findById(id: ID) {
        const { data } = await axios.get<BlogWithContent>(`/blog/${id}`);
        return data;
    },


    async deleteById(id: ID) {
        const { data } = await axios.delete<GenericStatusResponse>(`/blog/${id}`);
        return data;
    },
}

export default blogApi;