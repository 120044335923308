import { Button, Form, Input, Typography } from "antd";
import { useState } from "react";
import api from "../../api";
import { VerifyPasswordResetOTPRequest } from "../../api/auth";
import { ErrorMessage } from "../../shared/components/ErrorMessage";

export const VerifyOTPForm = ({
  email, onSuccess, onResend,
}: {
  email: string;
  onSuccess: (token: string) => void | Promise<void>;
  onResend: () => void | Promise<void>;
}) => {

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  return <Form<VerifyPasswordResetOTPRequest>
    layout='vertical'
    onFinish={async (data) => {
      try {
        setSubmitting(true);
        data.email = email;
        let response = await api.auth.verifyPasswordResetOtp(data);
        onSuccess(response.token);
      } catch (e) {
        setError(e);
      } finally {
        setSubmitting(false);
      }
    }}
  >
    <ErrorMessage error={error} />
    <Typography.Paragraph style={{ textAlign: 'center' }}>
      OTP Has been sent to your email, check your email. 
      If you didn't receive otp, check spam inbox.
    </Typography.Paragraph>
    <Form.Item
      label="OTP"
      name="otp"
      rules={[
        { required: true, message: 'otp is required' },
        { len: 6, message: 'six digit otp is required' }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item>
      <Button
        style={{ width: '100%' }}
        loading={submitting}
        disabled={submitting}
        type="primary"
        size='large'
        htmlType="submit"
      >
        Verify OTP
      </Button>
    </Form.Item>

    <Form.Item>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography.Link onClick={(e) => {
          e.preventDefault();
          onResend();
        }}>
          Didn't receive OTP? Resend.
        </Typography.Link>
      </div>
    </Form.Item>
  </Form>;
};
