import { ProCard, ProForm } from "@ant-design/pro-components"
import { useState } from "react"
import api from "../../api"
import { CreateUserRequest } from "../../api/users"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage"
import { UserFormFields } from "../components/UserFormFields"
import { message } from 'antd';
import { useNavigate } from "react-router-dom"

export const CreateUser = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();


    return <AntPageContainer>
        <ProCard bordered>
            <ProForm<CreateUserRequest>
                grid
                colProps={{ span: 12 }}
                onFinish={async (data) => {
                    data.blocked = data.blocked || false;

                    try {
                        await api.users.create(data);
                        message.success("Success")
                        navigate("/admin/users");
                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <UserFormFields />
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}