import { CKEditor, CKEditorEventHandler } from "ckeditor4-react"

export const CkEditorInput = ({
  value, 
  onChange
} : {
  value: string,
  onChange: (input : string) => void
}) => {

  const handleChange : CKEditorEventHandler<"change"> = (e) => {
    onChange(e.editor.getData());
  }

//   const uploadUrl = axios.getUri({url: "/uploads/ckimage"});

  return <CKEditor
    config={{
    //   filebrowserUploadUrl: uploadUrl,
      allowedContent: true,
      format_tags: 'p;h1;h2;h3;h4;h5;h6;pre',
      'stylesSet': [
        {
          'name': 'Table', 
          'element': 'table', 
          'attributes': {'class': 'table'}
        },
        {
          'name': 'Table Bordered', 
          'element': 'table', 
          'attributes': {'class': 'table table-bordered'}
        },
        {
          'name': 'Image', 
          'element': 'img', 
          'styles': {'width': null, 'height': null}
        },
      ]
    }}
    initData={value}
    onChange={handleChange}
    // @ts-ignore
    onFileUploadRequest={function(event) {
      // @ts-ignore
      let xhr = event.data?.fileLoader?.xhr;
      if (xhr) {
        // @ts-ignore
        xhr.withCredentials = true;
      }
    }}
  />
}