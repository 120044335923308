import { EditOutlined, EyeOutlined, PlusOutlined, LoginOutlined } from "@ant-design/icons"
import { ProTable } from "@ant-design/pro-components"
import { Button } from "antd"
import { Link } from "react-router-dom"
import api from "../../api"
import { UserBasic } from "../../api/entities"
import { DisplayUserRole, DisplayYesNo } from "../../api/enum-display"
import { UsersListRequest } from "../../api/users"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { yesNoToBoolean } from "../../utils/common"
import { useState } from "react"
import { ImpersonatePopup } from "../components/ImpersonatePopup"


export const ListUsers = () => {

    const [impersonatePopupOpen, setImpersonatePopupOpen] = useState(false);
    const [userId, setUserId] = useState<number | null>(null);

    return <AntPageContainer>
        {
            userId !== null && (
                <ImpersonatePopup 
                    open={impersonatePopupOpen}
                    setOpen={setImpersonatePopupOpen}
                    userId={userId}
                />
            )
        }
        <ProTable<UserBasic>
            headerTitle="Users"
            cardBordered
            search={{  
                labelWidth: 'auto',
                searchGutter: 8,
            }}
            options={{
                density: false,
                setting: false,
            }}
            form={{
                syncToUrl: true,
            }}
            toolBarRender={()=> [
                <Link to='create'>
                  <Button key="create" icon={<PlusOutlined/>} type="primary">
                    Create
                  </Button>
                </Link>,
              ]}
            columns={[
                {
                    key: 'id',
                    dataIndex: 'id',
                    title: 'ID',
                    hideInSearch: true,
                },
                {
                    key: 'name',
                    title: 'Name',
                    dataIndex: 'name',
                },
                {
                    key: 'name',
                    title: 'Username',
                    dataIndex: 'username',
                    hideInSearch: true,
                },
                {
                    key: 'email',
                    title: 'Email',
                    dataIndex: 'email',
                },
                {
                    key: 'role',
                    dataIndex: 'role',
                    title: 'Role',
                    valueEnum: DisplayUserRole,
                    valueType: 'select',
                },
                {
                    title: 'Coin Balance',
                    key: 'coin_balance',
                    dataIndex: 'coin_balance',
                    hideInSearch: true,
                },
                {
                    title: 'Blocked',
                    dataIndex: 'blocked',
                    valueType: 'select',
                    valueEnum: DisplayYesNo,
                    render: (dom, entity, index, action, schema) => {
                        return entity.blocked ? 'Yes': 'No'
                    },
                },
                {
                    title: 'Created At',
                    key: 'created_at',
                    dataIndex: 'created_at',
                    hideInSearch: true,
                    valueType: 'date',
                },
                {
                    title: 'Actions',
                    key: 'actions',
                    valueType: 'option',
                    render: (_, entity, index, action) => [
                        <Link to={`${entity.id}/edit`}>
                            <EditOutlined/>
                        </Link>,
                        <Link to={`${entity.id}/view`}>
                            <EyeOutlined />
                        </Link>,
                        <Button 
                            size="small" 
                            type="link"
                            onClick={() => {
                                setUserId(entity.id);
                                setImpersonatePopupOpen(true);
                            }}
                        >
                            <LoginOutlined />
                        </Button>
                    ]
                }
            ]}
            request={async (params, sort) => {
                const request = new UsersListRequest();
                request.setPageParams(params);
                request.blocked = yesNoToBoolean(params.blocked)
                request.email = params.email;
                request.name = params.name;
                request.role = params.role;
                return await api.users.index(request);
            }}
        />
    </AntPageContainer>
}