import { Card, Row } from 'antd';
import { Outlet } from 'react-router-dom';
import loginBg from '../assets/login-bg.jpeg';

export const AuthLayout = () => {
  return <Row 
    style={{ 
      width: '100%', height: '100vh', 
      backgroundImage: `url("${loginBg}")` 
    }}
    justify={'center'} 
    align={'middle'}
    >
      <Card style={{ width: 'min(100%, 450px)' }}>
        <Outlet />
      </Card>
  </Row>
}