import { ProCard } from "@ant-design/pro-components";
import { Col, Row, Skeleton, Typography } from "antd";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import api from "../../api";
import { AntPageContainer } from "../../shared/components/AntPageContainer";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { BasicSignalProviderInfo } from "../components/BasicSignalProviderInfo";
import { BasicUserInfo } from "../components/BasicUserInfo";
import { PlansInfo } from "../components/PlansInfo";

const { Title } = Typography

export const ViewSignalProvider = () => {
    const { id } = useParams();

    const {
        data: signalProvider,
        isLoading,
        error
    } = useSWR(`/api/signal-providers/${id}`, () => {
        return api.signalProviders.findById(id);
    });

    if (isLoading) {
        return <Skeleton />
    }

    if (error) {
        return <ErrorMessage error={error} />
    }

    if (!signalProvider) return null;

    return <AntPageContainer>
        <Row gutter={[12, 12]}>
            <Col span={24}>
                <ProCard bordered>
                    <BasicSignalProviderInfo signalProvider={signalProvider} />
                </ProCard>
            </Col>
            <Col span={24}>
                <ProCard bordered>
                    <BasicUserInfo user={signalProvider.owner} />
                </ProCard>
            </Col>
            <Col span={24}>
                <ProCard bordered>
                    <Title level={5}>Membership Plans</Title>
                    <PlansInfo plans={signalProvider.membership_plans} />
                </ProCard>
            </Col>
        </Row>

    </AntPageContainer>
}