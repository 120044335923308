import { useState } from "react";
import api from "../../api";
import { Button, Modal, Space } from "antd";
import { Link } from "react-router-dom";

export function ImpersonatePopup({
    open,
    setOpen,
    userId
} : { 
    open: boolean, 
    setOpen: (open: boolean) => void, 
    userId?: number 
}) {
    const [generating, setGenerating] = useState(false);
    const [url, setURL] = useState('');

    const generate = async () => {
        if (!userId) {
            return;
        }
        try {
            setGenerating(true);
            const { token } = await api.impersonate.impersonate({ user_id: userId });
            const url = new URL(`/impersonate/${token}`, process.env.REACT_APP_USER_SITE_URL as string);
            setURL(url.toString());
        } finally {
            setGenerating(false);
        }
    }

    return <Modal
        title="Generate Impersonate User Link" 
        open={open} 
        onCancel={() => setOpen(false)} 
        footer={null}
    >
        <Space size='large'>
            <Button 
                type="primary"
                onClick={() => generate()} 
                disabled={generating} 
                loading={generating}
            >
                Generate
            </Button>
            {
                url !== '' && (
                    <Link target="_blank" to={url}>
                        Login
                    </Link>
                )
            }
        </Space>
    </Modal>
}