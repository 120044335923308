import { createBrowserRouter, Navigate } from "react-router-dom";
import { ListAnalystSignupRequests } from "./analyst_signup_requests/pages/ListAnalystSignupRequests";
import { ListMembershipPlanRequest } from "./api/membership-plans";
import { Guest } from "./auth/components/Guest";
import { Protected } from "./auth/components/Protected";
import { AuthLayout } from "./auth/layouts/AuthLayout";
import { Login } from "./auth/pages/Login";
import { ResetPassword } from "./auth/pages/ResetPassword";
import { Dashboard } from "./dashboard/Dashboard";
import { EditFees } from "./fees/pages/EditFee";
import { CreateMembershipPlan } from "./membership_plans/pages/CreateMembershipPlan";
import { ListMembershipPlan } from "./membership_plans/pages/ListMembershipPlan";
import { ListRecharges } from "./recharges/pages/ListRecharges";
import { AdminLayout } from "./shared/layouts/AdminLayout";
import { CreateSignalProvider } from "./signal_providers/pages/CreateSignalProvider";
import { EditSignalProvider } from "./signal_providers/pages/EditSignalProvider";
import { ListSignalProviders } from "./signal_providers/pages/ListSignalProviders";
import { ViewSignalProvider } from "./signal_providers/pages/ViewSignalProvider";
import { ListTradeHistory } from "./trade_history/pages/ListTradeHistory";
import { CreateUser } from "./users/pages/CreateUser";
import { EditUser } from "./users/pages/EditUser";
import { ListUsers } from "./users/pages/ListUsers";
import { ViewUser } from "./users/pages/ViewUser";
import { CreateBlog } from './blog/pages/CreateBlog';
import { EditBlog } from "./blog/pages/EditBlog";
import { ListBlogs } from "./blog/pages/ListBlogs";
import { ListRechargePlans } from "./recharge_plans/pages/ListRechargePlans";
import { CreateRechargePlan } from "./recharge_plans/pages/CreateRechargePlan";
import { EditRechargePlan } from "./recharge_plans/pages/EditRechargePlan";
import { ListCandefiMembershipPlans } from './candefi_membership_plans/pages/ListCandefiMembershipPlans';
import { CreateCandeFiMembershipPlan } from "./candefi_membership_plans/pages/CreateCandeFiMembershipPlan";
import { EditCandeFiMembershipPlan } from "./candefi_membership_plans/pages/EditCandeFiMembershipPlan";
import { EditCandeFiMembershipPlanPrice } from "./candefi_membership_plans/pages/EditCandeFiMembershipPlanPrice";

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/admin/dashboard" replace={true} />
  },
  {
    path: "/admin",
    element: <Protected><AdminLayout/></Protected>,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'users',
        element: <ListUsers />,
      },
      {
        path: 'users/create',
        element: <CreateUser />,
      },
      {
        path: 'users/:id/edit',
        element: <EditUser />
      },
      {
        path: 'users/:id/view',
        element: <ViewUser />
      },
      {
        path: 'blogs',
        element: <ListBlogs />,
      },
      {
        path: 'blogs/create',
        element: <CreateBlog />,
      },
      {
        path: 'blogs/:id/edit',
        element: <EditBlog />
      },
      {
        path: 'signal-providers',
        element: <ListSignalProviders />,
      },
      {
        path: 'signal-providers/create',
        element: <CreateSignalProvider />
      },
      {
        path: 'signal-providers/:id/edit',
        element: <EditSignalProvider />
      },
      {
        path: 'signal-providers/:id/view',
        element: <ViewSignalProvider />,
      },
      {
        path: 'membership-plans',
        element: <ListMembershipPlan />
      },
      {
        path: 'membership-plans/create',
        element: <CreateMembershipPlan />
      },
      {
        path: 'recharges',
        element: <ListRecharges />,
      },
      {
        path: 'fees',
        element: <EditFees />,
      },
      {
        path: 'trade-history',
        element: <ListTradeHistory />,
      },
      {
        path: 'analyst-signup-requests',
        element: <ListAnalystSignupRequests />,
      },
      {
        path: 'recharge-plans',
        element: <ListRechargePlans />,
      },
      {
        path: 'recharge-plans/create',
        element: <CreateRechargePlan />,
      },
      {
        path: 'recharge-plans/:id/edit',
        element: <EditRechargePlan />
      },
      {
        path: 'candefi-membership-plans',
        element: <ListCandefiMembershipPlans />
      },
      {
        path: 'candefi-membership-plans/create',
        element: <CreateCandeFiMembershipPlan />
      },
      {
        path: 'candefi-membership-plans/:id/edit',
        element: <EditCandeFiMembershipPlan />
      },
      {
        path: 'candefi-membership-plans/:id/edit-price',
        element: <EditCandeFiMembershipPlanPrice />
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: (
          <Guest>
            <Login />
          </Guest>
        ),
      },
      {
        path: "reset-password",
        element: (
          <Guest>
            <ResetPassword />
          </Guest>
        ),
      },
    ],
  },
]);
