import {
    ProFormUploadButton,
    ProFormUploadButtonProps,
    SearchConvertKeyFn,
    SearchTransformKeyFn,
    ProFormUploadDraggerProps
} from "@ant-design/pro-components";
import { forwardRef } from "react";
import axios from 'axios';
import api from "../../../api";
import { getImageUrl } from "../../../utils/image-utils";

type FilePath = string | { response: { path: string } }

/**
 * 
 * converts file path returned by server
 * to object that upload input can use to display files.
 * 
 */
const convertFilePath = (path: FilePath): string | Record<string, any> => {
    if (typeof (path) === 'string') {
        return {
            path: path,
            url: getImageUrl(path),
            name: path.split("/").reverse()[0],
            response: { path: path }
        }
    }
    return path;
}

/**
 * 
 * convers file path returned by upload component
 * to string needed by the server.
 * 
 */
const transformFilePath = (path: FilePath) => {
    if (typeof (path) === 'string') return path;
    return path.response.path;
}

const transformSingle: SearchTransformKeyFn = (value, path, allValues) => {
    if (!value) {
        return {
            [path]: value,
        }
    }
    value = Array.isArray(value) ? value : [value]
    value = value.map(transformFilePath)
    value = value.length > 0 ? value[0] : null
    return {
        [path]: value,
    }
}

const transformMultiple: SearchTransformKeyFn = (value, path, allValues) => {
    return {
        [path]: value.map(transformFilePath),
    }
}

const convertValueSingle: SearchConvertKeyFn = (value, field) => {
    if (!value) return value;
    value = Array.isArray(value) ? value : [value];
    return value.map(convertFilePath);
}

const convertValueMultiple: SearchConvertKeyFn = (value, field) => {
    if (!value) return value;
    return value.map(convertFilePath);
}

type UploadInputProps = ProFormUploadDraggerProps & {
    multiple?: boolean
}

const CustomUploadInputRenderFunc: React.ForwardRefRenderFunction<any, UploadInputProps> = (
    {
        multiple = false,
        ...restProps
    },
    ref
) => {
    const convertValue = multiple ? convertValueMultiple : convertValueSingle;
    const transform = multiple ? transformMultiple : transformSingle;

    const fieldProps: ProFormUploadButtonProps['fieldProps'] = {
        method: 'PUT',
        accept: "image/png, image/jpeg, image/gif",
        customRequest: async ({
            file, onProgress, onError, onSuccess,
        }) => {
            if (!(file instanceof File)) {
                throw new Error("Invalid file");
            }
            const { type } = file;
            const { url, key } = await api.imageUpload.createUrl({ content_type: type });

            try {
                await axios.put(url, file, {
                    headers: {
                        'Content-Type': type,
                    },
                    onUploadProgress: (e) => {
                        if (e.progress !== undefined && onProgress !== undefined) {
                            onProgress({ percent: e.progress * 100 });
                        }
                    }
                });
                if (onSuccess !== undefined) {
                    onSuccess({ url, path: key });
                }
            } catch (e) {
                if (onError !== undefined) {
                    onError(e as Error);
                }
                return;
            }
        },
    }

    return <ProFormUploadButton
        title='Upload'
        {...restProps}
        // @ts-ignore
        ref={ref}
        convertValue={convertValue}
        transform={transform}
        fieldProps={fieldProps}
    />
}

export const CustomUploadInput = forwardRef(CustomUploadInputRenderFunc);