import axios from 'axios';
import { BASE_URL_AUTH } from './constants';
import transformError from './errors';
import attachToken from './interceptors/attach-token';
import saveToken from './interceptors/save-token';

// axios instance for making api calls
const instance = axios.create({
    baseURL: BASE_URL_AUTH
});

// save token after login
instance.interceptors.response.use(saveToken);

// attach token
instance.interceptors.request.use(attachToken);

// transforms errors
instance.interceptors.response.use(null, transformError);

export default instance;