import { AntPageContainer } from "../shared/components/AntPageContainer"
import { Typography } from "antd";
const { Title, Text } = Typography;

export const Dashboard = () => {
    return <AntPageContainer>
        <Title>Dashboard</Title>
    </AntPageContainer>
}

