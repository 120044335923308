import { ProCard, ProForm } from "@ant-design/pro-components"
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { EditUserRequest } from "../../api/users";
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { UserFormFields } from "../components/UserFormFields";

export const EditUser = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();
    const { id } = useParams();

    return <AntPageContainer>
        <ProCard
            bordered
        >
            <ProForm<EditUserRequest>
                grid
                colProps={{ span: 12 }}
                params={{ id }}
                request={async (params : Record<string, string>) => {
                    try {
                        const user = await api.users.findById(params.id);
                        return user as EditUserRequest;
                    } catch (e) {
                        navigate('/admin/users');
                    }
                }}
                onFinish={async (data) => {
                    data.blocked = data.blocked || false;

                    try {
                        await api.users.edit(id, data);
                        message.success("Success")
                        navigate("/admin/users");
                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <UserFormFields/>
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}