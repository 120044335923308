import { ProFormDigit, ProFormMoney, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-components"
import { DisplayUserRole } from "../../api/enum-display"
import { usernameRegex, usernameRegexExplanation } from "../../utils/common"

export const UserFormFields = () => {
    return <>
        <ProFormText
            label="Name"
            name="name"
            rules={[
                { required: true, message: 'name is required' }
            ]}
        />
        <ProFormText
            label="Email"
            name="email"
            fieldProps={{
                type: "email",
            }}
            rules={[
                { required: true, message: 'email is required' }
            ]}
        />
        <ProFormText
            label="Username"
            name="username"
            rules={[
                { required: true, message: 'username is required' },
                { pattern: usernameRegex, message: usernameRegexExplanation },
            ]}
        />
        <ProFormSelect
            label="Role"
            name="role"
            valueEnum={DisplayUserRole}
            required={true}
            rules={[
                { required: true, message: 'role is required' }
            ]}
        />
        <ProFormDigit
            name="coin_balance"
            label="Coin Balance"
            min={0}
            rules={[
                { required: true, message: 'coin balance is required' }
            ]}
            fieldProps={{
                precision: 2,
            }}
        />
        <ProFormSwitch
            name="blocked"
            label="Blocked"
        />
    </>
}