export enum UserRole {
    Trader = 'trader',
    Admin = 'admin',
    Analyst = 'analyst',
}

export enum YesNo {
    Yes = 'yes',
    No = 'no',
    Any = 'any'
}

export enum BrokerType {
    Webull = 'webull',
    Robinhood = 'robinhood',
    TDA = 'tda'
}

export enum TimeInForce {
    Day = 'DAY',
    GoodTillCancelled = 'GTC',
    ImmediateOrCancelled = 'IOC',
}

export enum SignalProviderType {
    Individual = 'individual'
}

export enum BillingIntervalUnit {
    Week = 'week',
    Month = 'month',
    Year = 'year'
}

export enum MembershipStatus {
    Draft = 'draft',
    Active = 'active',
    Completed = 'completed',
    Cancelled = 'cancelled',
}


export enum AccountBalanceRechargeStatus {
    Pending = 'pending',
    Success = 'success',
    Failed = 'failed',
}

export enum TradeHistoryAction {
    PlaceOrder = 'place_order',
    ModifyOrder = 'modify_order',
    CancelOrder = 'cancel_order',
}

export enum AnalystSignupRequestStatus {
    Pending = 'pending',
    Approved = 'approved',
    Rejected = 'rejected'
}