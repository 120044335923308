import { EyeOutlined } from "@ant-design/icons"
import { Table } from "antd"
import { Link } from "react-router-dom"
import { MembershipPlanBasic } from "../../api/entities"
import { formatDate, formatMoney } from "../../utils/common"

// id: number
// name: string
// active: boolean
// amount: string | null
// billing_interval: number
// billing_interval_unit: BillingIntervalUnit
// billing_cycles: number
// created_at: string
// updated_at: string 

export const PlansInfo = ({
    plans
} : {
    plans: MembershipPlanBasic[],
}) => {
    return <Table<MembershipPlanBasic>
        dataSource={plans}
        pagination={false}
        columns={[
            { key: 'id', dataIndex: 'id', title: 'Id' },
            { 
                key: 'active', 
                dataIndex: 'active', 
                title: 'Active', 
                render: (_, row) => row.active ? 'Yes' : 'No',
            },
            {
                key: 'amount',
                title: 'Amount',
                render: (_, row) => formatMoney(row.amount || ''),
            },
            {
                key: 'billing_cycles',
                title: 'Cycles',
                render: (_, row) => row.billing_cycles == 0 ? 'Infnite' : row.billing_cycles,
            },
            {
                key: 'billing_interval',
                title: 'Billing Interval',
                render: (_, row) => `${row.billing_interval} ${row.billing_interval_unit}`,
            },
            {
                key: 'created_at',
                title: 'Created At',
                render: (_, row) => formatDate(row.created_at),
            },
            {
                key: 'actions',
                title: 'Actions',
                render: (_, row) => [
                    <Link 
                        target='_blank'
                        to={`/admin/membership-plans/${row.id}/view`}>
                        <EyeOutlined />
                    </Link>
                ]
            }
        ]}
    />
}