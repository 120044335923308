import axios from "./axios";
import { AnalystSignupRequestStatus } from "./enums";
import { GenericStatusResponse, ID, ListRequest, PaginatedResponse } from "./types";

export class AnalystSignupRequestListRequest extends ListRequest {
    name_or_email?: string
    status?: AnalystSignupRequestStatus
}

export interface AnalystSignupRequest {
    id: number
    name: string
    email: string
    username: string
    status: AnalystSignupRequestStatus
    created_at: string
    updated_at: string
}


const analystSignupRequestApi = {
    async index(request : AnalystSignupRequestListRequest) {
        const { data } = await axios.get<PaginatedResponse<AnalystSignupRequest>>('analyst-signup-requests', { params: request });
        return data;
    },

    async approve(id : ID) {
        const { data } = await axios.patch<GenericStatusResponse>(`analyst-signup-requests/${id}/approve`);
        return data;
    },

    async reject(id : ID) {
        const { data } = await axios.patch<GenericStatusResponse>(`analyst-signup-requests/${id}/reject`);
        return data;
    }
}

export default analystSignupRequestApi;