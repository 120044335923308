import { Alert } from "antd";

export const ErrorMessage = ({ error, style } : { error: unknown, style?: React.CSSProperties }) => {
  const alertStyle = style || { margin: '24px 0' }
  const message = getErrorMessage(error);
  if (message === null) {
    return null;
  }
  return <Alert style={alertStyle} type="error" message={message} />
}

export const getErrorMessage = (error: unknown) => {
  if (error === null) {
    return null;
  }
  if (typeof(error) === 'string') {
    return error;
  }
  if (error instanceof Error) {
    return error.message
  }
  return "Something went wrong";
}