import { InternalAxiosRequestConfig } from 'axios';
import { REFRESH_PATH } from '../constants';
import refreshInstance from '../refresh-axios';
import { getAuthInfo } from '../utils/token-storage';

export default async function refreshAccessToken(config : InternalAxiosRequestConfig<any> ) {

    // get access token expiry date
    let authInfo = getAuthInfo();
    let accessTokenExpiry = null;
    
    if (authInfo !== null && authInfo.access_token_expires) {
        let expiryDate = new Date(authInfo.access_token_expires);
        let isValidDate = !isNaN(expiryDate.getTime())
        if (isValidDate) {
            accessTokenExpiry = expiryDate;
        }
    }
    
    // expiry date is not set do nothing
    if (accessTokenExpiry === null) {
        return config;
    }

    let accessTokenExpired = accessTokenExpiry.getTime() < (new Date()).getTime();
    if (accessTokenExpired) {
        // refresh token before proceeding
        await refreshInstance.post(REFRESH_PATH, null, {
            headers: {
                'Authorization': `Bearer ${authInfo?.refresh_token}`
            }
        });
    }

    return config;
};