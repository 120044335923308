import { UserBasic, UserWithDetails } from "./entities"
import { GeneircIDResponse, GenericStatusResponse, ID, ListRequest, PaginatedResponse } from "./types"
import axios from './axios';
import { UserRole } from "./enums";

export class UsersListRequest extends ListRequest {
    name_or_email?: string
    name?: string
    email?: string
    role?: string
    blocked?: boolean  
}

export interface CreateUserRequest {
    name: string
    email: string
    role: UserRole
    blocked: boolean
    coin_balance: number
}

export interface EditUserRequest extends CreateUserRequest {}

const usersApi = {
    async index(request : UsersListRequest) {
        const { data } = await axios.get<PaginatedResponse<UserBasic>>('/users', {
            params: request,
        });
        return data;
    },

    async create(request: CreateUserRequest) {
        const { data } = await axios.post<GeneircIDResponse>('/users', request);
        return data;
    },

    async edit(id: ID, request: EditUserRequest) {
        const { data } = await axios.patch<GenericStatusResponse>(`/users/${id}`, request);
        return data;
    },

    async findById(id: ID) {
        const { data } = await axios.get<UserWithDetails>(`/users/${id}`);
        return data;
    }
}

export default usersApi;