import { ProCard, ProForm } from "@ant-design/pro-components"
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { BlogFormFields } from "../components/blog-form-fields";
import { EditBlogRequest } from "../../api/blog";

export const EditBlog = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();
    const { id } = useParams();

    return <AntPageContainer>
        <ProCard
            bordered
        >
            <ProForm<EditBlogRequest>
                grid
                colProps={{ span: 12 }}
                params={{ id }}
                request={async (params : Record<string, string>) => {
                    try {
                        const blog = await api.blog.findById(params.id);
                        return blog as EditBlogRequest;
                    } catch (e) {
                        navigate('/admin/blogs');
                    }
                }}
                onFinish={async (data) => {
                    data.published = data.published || false;

                    try {
                        await api.blog.edit(id, data);
                        message.success("Success")
                        navigate("/admin/blogs");
                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <BlogFormFields/>
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}