import { ProCard, ProForm, ProFormCheckbox, ProFormDigit, ProFormMoney, ProFormSelect, ProFormText } from "@ant-design/pro-components"
import { message } from "antd"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { DisplayBillingIntervalUnit } from "../../api/enum-display"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { ErrorMessage } from "../../shared/components/ErrorMessage"
import { AdminCreateCandeFiMembershipPlanRequest } from "../../api/candefi-membership-plans"
import { BillingIntervalUnit } from "../../api/enums"

interface CreateMembershipPlanForm extends AdminCreateCandeFiMembershipPlanRequest {}

export const CreateCandeFiMembershipPlan = () => {
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();

    return <AntPageContainer>
        <ProCard>
            <ProForm<CreateMembershipPlanForm>
                grid
                onFinish={async (data) => {

                    // default value is undefined
                    // but we want false
                    data.can_autotrade = data.can_autotrade || false;
                    data.can_buy_extra_coins = data.can_buy_extra_coins || false;

                    try {
                        await api.candeFiMembershipPlans.create(data);
                        message.success("Success")
                        navigate("/admin/candefi-membership-plans");
                    } catch (e) {
                        setError(e);
                    }
                }}
            >
                <ProFormText
                    required
                    label="Name"
                    colProps={{ span: 12 }}
                    name="name"
                    rules={[
                        { required: true, message: 'name is required' },
                    ]}
                />
                <ProFormSelect
                    required
                    colProps={{ span: 12 }}
                    name="billing_interval"
                    label="Billing Interval"
                    valueEnum={{
                        [BillingIntervalUnit.Month]: DisplayBillingIntervalUnit[BillingIntervalUnit.Month],
                        [BillingIntervalUnit.Year]: DisplayBillingIntervalUnit[BillingIntervalUnit.Year],
                    }}
                    rules={[
                        { required: true, message: 'billing interval is required' },
                    ]}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 24 }}
                    name="monthly_coins"
                    label="Monthly Coins"
                    min={0}
                    rules={[
                        { required: true, message: 'monthly coins is required' },
                    ]}
                />
                <ProFormMoney
                    required
                    colProps={{ span: 24 }}
                    name="price"
                    label="Price"
                    placeholder={"0 for free plan"}
                    min={0}
                    rules={[
                        { required: true, message: 'price is required' },
                    ]}
                />
                <ProFormCheckbox
                    colProps={{ span: 12 }}
                    name="can_autotrade"
                    label="Can Autotrade"
                    fieldProps={{
                        defaultChecked: false,
                    }}
                />
                <ProFormCheckbox
                    colProps={{ span: 12 }}
                    name="can_buy_extra_coins"
                    label="Can Buy Extra Coins"
                    fieldProps={{
                        defaultChecked: false,
                    }}
                />
                <ProFormDigit
                    required
                    colProps={{ span: 24 }}
                    name="max_autotrade_bots"
                    label="Max Autotrade Bots"
                    min={0}
                    rules={[
                        { required: true, message: 'max autotrade bots is required' },
                    ]}
                />
            </ProForm>
            {
                error && <ErrorMessage error={error} />
            }
        </ProCard>
    </AntPageContainer>
}