import axios from 'axios';
import { BASE_URL_AUTH } from './constants';
import transformError from './errors';
import saveToken from './interceptors/save-token';

// axios instance for refreshing access token
const refreshInstance = axios.create({
    baseURL: BASE_URL_AUTH
});

refreshInstance.interceptors.response.use(saveToken);

// transforms errors
refreshInstance.interceptors.response.use(null, transformError);

export default refreshInstance;