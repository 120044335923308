import axios from "./axios";

export interface CreateImageUploadUrlRequest {
  content_type: string
}

export interface ImageUploadUrlResponse {
  url: string
  key: string
}

const imageUploadApi = {
  async createUrl(request: CreateImageUploadUrlRequest) {
    const { data } = await axios.post<ImageUploadUrlResponse>('/images', request);
    return data;
  }
}

export default imageUploadApi;