import { ProFormField, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-components"
import { CkEditorInput } from "../../shared/components/inputs/CKEditorInput"
import { CustomUploadInput } from "../../shared/components/inputs/custom-upload-input";

export const BlogFormFields = () => {
    return <>
        <ProFormText
            label="Title"
            name="title"
            rules={[
                { required: true, message: 'title is required' }
            ]}
        />
        <ProFormText
            label="Slug"
            name="slug"
            rules={[
                { required: true, message: 'slug is required' },
                { pattern: /^[a-zA-Z\-]+$/, message: 'only alphabet numbers and minus sign allowed' },
            ]}
        />
        <ProFormTextArea
            label="Excerpt"
            name="excerpt"
            colProps={{ span: 24 }}
            rules={[
                { required: true, message: 'excerpt is required' }
            ]}
        />
        <CustomUploadInput
          multiple={false}
          name='cover_image'
          label='Cover image'
          rules={[
            { required: true, message: 'required' }
          ]}
        />
        <ProFormField
            label="Content"
            name="content"
            colProps={{ span: 24 }}
            rules={[
                { required: true, message: 'content is required' }
            ]}
        >
            {/* @ts-ignore */}
            <CkEditorInput />
        </ProFormField>
        <ProFormSwitch
            name="published"
            label="Published"
        />
    </>
}