import axios from "./axios"
import { CandeFiMembershipPlanBasic } from "./entities"
import { BillingIntervalUnit } from "./enums"

export interface AdminCreateCandeFiMembershipPlanRequest {
    name: string
    price: number | null
    billing_interval: BillingIntervalUnit
    can_autotrade: boolean
    can_buy_extra_coins: boolean
    max_autotrade_bots: number
    monthly_coins: number
}

export interface AdminEditCandeFiMembershipPlanRequest {
    name: string
    can_autotrade: boolean
    can_buy_extra_coins: boolean
    monthly_coins: number
    max_autotrade_bots: number
}

export type AdminChangeMembershipPlanPriceRequest = {
    price: number
}

const candeFiMembershipPlansApi = {
    async create(request: AdminCreateCandeFiMembershipPlanRequest) {
        const { data } = await axios.post<void>('/candefi-membership-plans', request);
        return data
    },

    async listAll() {
        const { data } = await axios.get<CandeFiMembershipPlanBasic[]>('/candefi-membership-plans');
        return data
    },

    async findById(id: unknown) {
        const {
            data
        } = await axios.get<CandeFiMembershipPlanBasic | null>(`/candefi-membership-plans/${id}`);
        return data;
    },

    async edit(id: unknown, request: AdminEditCandeFiMembershipPlanRequest) {
        const {
            data
        } = await axios.patch<null>(`/candefi-membership-plans/${id}`, request);
        return data;
    },

    async editPrice(id: unknown, request: AdminChangeMembershipPlanPriceRequest) {
        const {
            data
        } = await axios.patch<null>(`/candefi-membership-plans/${id}/price`, request);
        return data;
    },

    async delete(id: unknown) {
        const {
            data
        } = await axios.delete<null>(`/candefi-membership-plans/${id}`);
        return data;
    },
}

export default candeFiMembershipPlansApi