import { EyeOutlined } from "@ant-design/icons"
import { Table } from "antd"
import { Link } from "react-router-dom"
import { SignalProviderBasic } from "../../api/entities"
import { DisplaySignalProviderType } from "../../api/enum-display"

export const UserSignalProviders = ({
    signalProviders
} : {
    signalProviders : SignalProviderBasic[]
}) => {
    return <Table<SignalProviderBasic>
        pagination={false}
        dataSource={signalProviders}
        columns={[
            { title: 'ID', key: 'id', dataIndex: 'id' },
            { 
                title: 'Username', 
                key: 'username', 
                dataIndex: 'username',
                render: (_, provider) => `@${provider.username}`
            },
            { 
                title: 'Type', 
                key: 'type',
                render: (_, entity) => {
                    return DisplaySignalProviderType[entity.type];
                }
            },
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: 'Bio',
                key: 'bio',
                dataIndex: 'bio',
            },
            {
                key: 'actions',
                title: 'Actions',
                render: (_, entity) => [
                    <Link to={`/admin/signal-providers/${entity.id}/view`}>
                        <EyeOutlined />
                    </Link>
                ]
            }
        ]}
    />
}