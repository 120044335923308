import { Button, Descriptions } from "antd"
import { Link } from "react-router-dom"
import { UserBasic } from "../../api/entities"

export const BasicUserInfo = ({
    user
} : {
    user: UserBasic
}) => {
    return <Descriptions title="Owner / Analyst">
        <Descriptions.Item label="Id">
            <Link to={`/admin/users/${user.id}/view`}>
                <Button type="default">
                    {user.id}
                </Button>
            </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Email">
            {user.email}
        </Descriptions.Item>
        <Descriptions.Item label="Name">
            {user.name}
        </Descriptions.Item>
        <Descriptions.Item label="Blocked">
            { user.blocked ? 'Yes': 'No' }
        </Descriptions.Item>
    </Descriptions>
}