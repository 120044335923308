import { ProTable } from "@ant-design/pro-components"
import { Link } from "react-router-dom"
import api from "../../api"
import { AccountBalanceRechargeWithUser } from "../../api/entities"
import { DisplayAccountBalanceRechargeStatus } from "../../api/enum-display"
import { ListRechargesRequest } from "../../api/recharges"
import { AntPageContainer } from "../../shared/components/AntPageContainer"
import { formatDate, formatMoney } from "../../utils/common"

export const ListRecharges = () => {
    return <AntPageContainer>
        <ProTable<AccountBalanceRechargeWithUser>
            headerTitle="Recharges"
            cardBordered
            search={{
                labelWidth: 'auto',
                searchGutter: 8,
            }}
            options={{
                density: false,
                setting: false,
            }}
            form={{
                syncToUrl: true,
            }}
            request={(params, sort, filter) => {
                const request = new ListRechargesRequest();
                request.setPageParams(params);
                request.user_id = params.user_id;
                request.user_name = params.user_name;
                request.user_email = params.user_email;
                request.status = params.status;
                if (params.date && Array.isArray(params.date) && params.date.length === 2) {
                    request.min_date = params.date[0] || undefined;
                    request.max_date = params.date[1] || undefined;
                }
                return api.recharges.index(request);
            }}
            columns={[
                { key: 'id', dataIndex: 'id', title: 'ID', hideInSearch: true },
                {
                    key: 'amount',
                    dataIndex: 'amount',
                    render: (_, row) => formatMoney(row.amount),
                    title: 'Amount',
                    hideInSearch: true,
                },
                {
                    key: 'status',
                    dataIndex: 'status',
                    valueType: 'select',
                    valueEnum: DisplayAccountBalanceRechargeStatus,
                    title: 'Status',
                },
                {
                    key: 'user',
                    title: 'User',
                    render: (_, row) => {
                        const user = row.user;
                        return <Link to={`/admin/users/${user.id}/view`}>
                            {user.name} ({user.email})
                        </Link>;
                    },
                    hideInSearch: true,
                },
                {
                    key: 'createdAt',
                    title: 'Created At',
                    dataIndex: 'created_at',
                    render: (_, row) => formatDate(row.created_at),
                    hideInSearch: true,
                },
                {
                    key: 'updatedAt',
                    title: 'Updated At',
                    dataIndex: 'updated_at',
                    render: (_, row) => formatDate(row.updated_at),
                    hideInSearch: true,
                },
                {
                    key: 'user_id',
                    title: 'User Id',
                    valueType: 'digit',
                    hideInTable: true,
                },
                {
                    key: 'user_name',
                    title: 'User Name',
                    hideInTable: true,
                },
                {
                    key: 'user_email',
                    title: 'User Email',
                    hideInTable: true,
                },
                {
                    key: 'date',
                    title: 'Date',
                    valueType: 'dateTimeRange',
                    hideInTable: true,
                    fieldProps: {
                        allowEmpty: [true, true],
                    }
                }
            ]}
        />
    </AntPageContainer>
}